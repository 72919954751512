import { configureStore, Selector } from '@reduxjs/toolkit'
import rootReducer from 'app/rootReducer'
import { cacheStoreKey } from 'core/caching/cacheReducers'
import { clientStoreKey } from 'core/client/clientReducers'
import { sessionStoreKey } from 'core/session/sessionReducers'
import { customMigrationKey } from 'openstack/components/vms/migrations/reducers'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [cacheStoreKey, clientStoreKey, sessionStoreKey, customMigrationKey],
}

const reducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetStateFromSelector<S> = S extends Selector<infer State> ? State : never
export type AppSelector<T> = Selector<RootState, T>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
