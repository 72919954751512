import ApiService from 'api-client/ApiService'
import { NovaServersResponse } from 'app/plugins/openstack/components/vms/model'

class Nova extends ApiService {
  public getClassName() {
    return 'nova'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('nova', 'public')
  }

  flavorsUrl = () => `/flavors`
  instancesUrl = () => `/servers`
  hypervisorsUrl = () => `/os-hypervisors`
  sshKeysUrl = () => `/os-keypairs`

  checkHealth = async () => {
    return this.client.basicGet<any>({
      url: `/`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'checkHealth',
      },
    })
  }

  getVersionDetails = async () => {
    const response = await this.client.basicGet<any>({
      url: `/`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNovaVersion',
      },
    })
    return response
  }

  getFlavors = async () => {
    // const url = `${this.flavorsUrl()}/detail?is_public=no`
    const response = await this.client.basicGet<any>({
      url: `/flavors/detail?is_public=None`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackFlavors',
        config: {
          headers: {
            'OpenStack-API-Version': 'compute 2.95',
            // 'X-OpenStack-Nova-API-Version': '2.61',
          },
        },
      },
    })
    return response.flavors
  }

  getFlavor = async (flavorId) => {
    const response = await this.client.basicGet<any>({
      url: `/flavors/${flavorId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackFlavor',
        config: {
          headers: {
            'OpenStack-API-Version': 'compute 2.95',
            // 'X-OpenStack-Nova-API-Version': '2.61',
          },
        },
      },
    })
    return response.flavor
  }

  createFlavor = async (body) => {
    const response = await this.client.basicPost<any>({
      url: `/flavors`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createFlavor',
      },
    })
    return response.flavor
  }

  createFlavorMetadata = async ({ id, body }) => {
    const response = await this.client.basicPost<any>({
      url: `/flavors/${id}/os-extra_specs`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createFlavorMetadata',
      },
    })
    return response
  }

  updateFlavorMetadata = async ({ id, body, key }) => {
    const response = await this.client.basicPut<any>({
      url: `/flavors/${id}/os-extra_specs/${key}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateFlavorMetadata',
      },
    })
    return response
  }

  deleteFlavorMetadata = async ({ id, key }) => {
    return this.client.basicDelete<any>({
      url: `/flavors/${id}/os-extra_specs/${key}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteFlavorMetadata',
      },
    })
  }

  getFlavorTenants = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/flavors/${id}/os-flavor-access`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getFlavorTenants',
      },
    })
    return response.flavor_access
  }

  addFlavorTenant = async ({ id, body }) => {
    const response = await this.client.basicPost<any>({
      url: `/flavors/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addFlavorTenant',
      },
    })
    return response
  }

  removeFlavorTenant = async ({ id, body }) => {
    const response = await this.client.basicPost<any>({
      url: `/flavors/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'removeFlavorTenant',
      },
    })
    return response
  }

  deleteFlavor = async (id) => {
    return this.client.basicDelete<any>({
      url: `/flavors/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteFlavor',
      },
    })
  }

  getInstances = async () => {
    const response = await this.client.basicGet<NovaServersResponse>({
      url: `/servers/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackVms',
      },
    })
    return response.servers
  }

  getAllInstances = async () => {
    const response = await this.client.basicGet<NovaServersResponse>({
      url: `/servers/detail?all_tenants=True`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllOpenstackVms',
      },
    })
    return response.servers
  }

  getInstancesForHost = async (hostId) => {
    const response = await this.client.basicGet<NovaServersResponse>({
      url: `/servers/detail?all_tenants=True&deleted=False&host=${hostId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostOpenstackVms',
      },
    })
    return response.servers
  }

  getInstance = async (instanceId) => {
    const response = await this.client.basicGet<any>({
      url: `/servers/${instanceId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackVm',
      },
    })
    return response.server
  }

  createInstance = async (body) => {
    const response = await this.client.basicPost<any>({
      url: `/servers`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenstackVm',
        config: {
          headers: {
            'OpenStack-API-Version': 'compute 2.95',
          },
        },
      },
    })
    return response.server
  }

  getInstaceDetails = async (id) => {
    const url = `/servers/${id}`
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getInstaceDetail',
      },
    })
    return response.server
  }

  updateInstance = async (id, body) => {
    const projectId = this.client.activeProjectId
    const url = `/servers/${id}`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackVm',
      },
    })
    return response.server
  }

  setInstanceMetadata = async ({ id, body }) => {
    const response = await this.client.basicPut<any>({
      url: `/servers/${id}/metadata`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackVmMetadata',
      },
    })
    return response.metadata
  }

  deleteInstance = async (id) => {
    return this.client.basicDelete({
      url: `/servers/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteOpenstackVm',
      },
    })
  }

  async getServerGroups() {
    const response = await this.client.basicGet<any>({
      url: `/os-server-groups`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getServerGroups',
      },
    })
    return response.server_groups
  }

  createServerGroup = async (body) => {
    const response = await this.client.basicPost<any>({
      url: `/os-server-groups`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createServerGroup',
      },
    })
    return response.server_group
  }

  deleteServerGroup = async (id) => {
    return this.client.basicDelete({
      url: `/os-server-groups/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteServerGroup',
      },
    })
  }

  async getHypervisors() {
    // const url = `${this.hypervisorsUrl()}/detail`
    const response = await this.client.basicGet<any>({
      url: `/os-hypervisors/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHypervisors',
      },
    })
    return response.hypervisors
  }

  getHypervisor = async (id) => {
    const response = await this.client.basicGet<any>({
      url: `/os-hypervisors/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHypervisor',
      },
    })
    return response.hypervisor
  }

  enableHypervisorScheduling = async (body) => {
    const response = await this.client.basicPut<any>({
      url: '/os-services/enable',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'enableHypervisorScheduling',
      },
    })
    return response
  }

  disableHypervisorScheduling = async (body) => {
    const response = await this.client.basicPut<any>({
      url: '/os-services/disable',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'disableHypervisorScheduling',
      },
    })
    return response
  }

  async getHostAggregates() {
    const response = await this.client.basicGet<any>({
      url: `/os-aggregates`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostAggregates',
      },
    })
    return response.aggregates
  }

  createHostAggregate = async (body) => {
    const response = await this.client.basicPost<any>({
      url: `/os-aggregates`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createHostAggregate',
      },
    })
    return response.aggregate
  }

  getHostAggregate = async (id) => {
    const response = await this.client.basicGet<any>({
      url: `/os-aggregates/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostAggregate',
      },
    })
    return response.aggregate
  }

  updateHostAggregate = async ({ id, body }) => {
    const response = await this.client.basicPut<any>({
      url: `/os-aggregates/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateHostAggregate',
      },
    })
    return response.aggregate
  }

  hostAggregateAction = async ({ id, body }) => {
    const response = await this.client.basicPost<any>({
      url: `/os-aggregates/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'hostAggregateAction',
      },
    })
    return response.aggregate
  }

  deleteHostAggregate = async (id) => {
    return this.client.basicDelete<any>({
      url: `/os-aggregates/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteHostAggregate',
      },
    })
  }

  async getAvailabilityZones() {
    const response = await this.client.basicGet<any>({
      url: `/os-availability-zone/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAvailabilityZones',
      },
    })
    return response.availabilityZoneInfo
  }

  async getSshKeys() {
    const response = await this.client.basicGet<any>({
      url: `/os-keypairs`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSshKeys',
      },
    })
    return response.keypairs
  }

  async getSshKey(name) {
    const response = await this.client.basicGet<any>({
      url: `/os-keypairs/${name}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSshKey',
      },
    })
    return response.keypair
  }

  createSshKey = async (body) => {
    const response = await this.client.basicPost<any>({
      url: `/os-keypairs`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSshKeys',
      },
    })
    return response.keypair
  }

  async deleteSshKey(name) {
    return this.client.basicDelete<any>({
      url: `/os-keypairs/${name}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSshKey',
      },
    })
  }

  instanceAction = async ({ id, body }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'openstackInstanceAction',
      },
    })
  }

  migrateAction = async ({ id, body }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'migrateInstanceAction',
        config: {
          headers: {
            'OpenStack-API-Version': 'compute 2.95',
          },
        },
      },
    })
  }

  attachVolume = async ({ vmId, body }) => {
    return this.client.basicPost<any>({
      url: `/servers/${vmId}/os-volume_attachments`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'attachVolumeToInstance',
      },
    })
  }

  detachVolume = async ({ vmId, volumeId }) => {
    return this.client.basicDelete<any>({
      url: `/servers/${vmId}/os-volume_attachments/${volumeId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'detachVolumeFromInstance',
      },
    })
  }

  createConsole = async ({ vmId }) => {
    // Try microversion 2.95 and use remote_consoles endpoint
    return this.client.basicPost<any>({
      url: `/servers/${vmId}/remote-consoles`,
      // url: `/servers/${vmId}/action`,
      // body: {
      //   'os-getVNCConsole': {
      //     type: 'novnc',
      //   },
      // },
      body: {
        remote_console: {
          protocol: 'vnc',
          type: 'novnc',
        },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVmConsole',
        config: {
          headers: {
            // 'OpenStack-API-Version': 'compute 2.87', // sbux-scaletest
            'OpenStack-API-Version': 'compute 2.95',
          },
        },
      },
    })
  }

  addSecurityGroupAction = async ({ id, securityGroupName }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body: {
        addSecurityGroup: {
          name: securityGroupName,
        },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'openstackInstanceAction',
      },
    })
  }

  removeSecurityGroupAction = async ({ id, securityGroupName }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body: {
        removeSecurityGroup: {
          name: securityGroupName,
        },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'openstackInstanceAction',
      },
    })
  }

  rescueVmAction = async ({ id, body }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'rescueVmAction',
        config: {
          headers: {
            'OpenStack-API-Version': 'compute 2.95',
          },
        },
      },
    })
  }

  unrescueVmAction = async ({ id }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/action`,
      body: {
        unrescue: null,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'unrescueVmAction',
      },
    })
  }

  createInterface = async ({ id, body }) => {
    return this.client.basicPost<any>({
      url: `/servers/${id}/os-interface`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVmInterface',
      },
    })
  }

  detachInterface = async ({ id, portId }) => {
    return this.client.basicDelete<any>({
      url: `/servers/${id}/os-interface/${portId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'detachInterfaceFromVm',
      },
    })
  }

  getQuotas = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/os-quota-sets/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNovaQuotas',
      },
    })
    return response.quota_set
  }

  updateQuotas = async ({ id, body }) => {
    const response = await this.client.basicPut<any>({
      url: `/os-quota-sets/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNovaQuotas',
      },
    })
    return response.quota_set
  }

  getQuotaUsage = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/os-quota-sets/${id}/detail`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getQuotaUsage',
      },
    })
    return response.quota_set
  }

  getQuotasForUser = async ({ id, userId }) => {
    const response = await this.client.basicGet<any>({
      url: `/os-quota-sets/${id}/detail?user_id=${userId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNovaQuotasForUser',
      },
    })
    return response.quota_set
  }

  updateQuotasForUser = async ({ id, userId, body }) => {
    const response = await this.client.basicPut<any>({
      url: `/os-quota-sets/${id}?user_id=${userId}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNovaQuotasForUser',
      },
    })
    return response.quota_set
  }

  getVmEvents = async ({ id }) => {
    const response = await this.client.basicGet<any>({
      url: `/servers/${id}/os-instance-actions`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVmEvents',
      },
    })
    return response.instanceActions
  }

  getVmEventDetails = async ({ vmId, requestId }) => {
    const response = await this.client.basicGet<any>({
      url: `/servers/${vmId}/os-instance-actions/${requestId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVmEventDetails',
      },
    })
    return response.instanceAction
  }
}

export default Nova
