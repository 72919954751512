import {
  listMngmGroupMappings,
  listMngmGroups,
} from 'account/components/ssoManagement/groups/new-actions'
import { listMngmRoles } from 'account/components/userManagement/roles/new-actions'
import {
  listTenantRoleAssignments,
  listTenants,
  listUserTenants,
} from 'account/components/userManagement/tenants/new-actions'
import {
  listCredentials,
  listUserRoleAssignments,
  listUsers,
} from 'account/components/userManagement/users/new-actions'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { listResMgrHosts } from 'app/plugins/infrastructure/components/resMgrHosts/actions'
import ListAction from 'core/actions/ListAction'
import {
  listApiGroups,
  listApiResources,
} from 'k8s/components/access-control/api-groups/new-actions'
import { listClusterRoleBindings } from 'k8s/components/access-control/cluster-role-bindings/actions'
import { listClusterRoles } from 'k8s/components/access-control/cluster-roles/actions'
import { listRoleBindings } from 'k8s/components/access-control/role-bindings/actions'
import { listRoles } from 'k8s/components/access-control/roles/actions'
import { listApps, listAppsAvailableToCluster } from 'k8s/components/app-catalog/apps/new-actions'
import { listDeployedApps } from 'k8s/components/app-catalog/deployed-apps/new-actions'
import {
  listRepositories,
  listRepositoriesForCluster,
} from 'k8s/components/app-catalog/repositories/new-actions'
import { listKaapiClusterUpgradeJobs } from 'k8s/components/kaapi/cluster-upgrade/actions'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { listKaapiConfigMaps } from 'k8s/components/kaapi/config-maps/actions'
import { listKaapiHostedControlPlanes } from 'k8s/components/kaapi/hosted-control-plane/actions'
import { listkaapiKubeadmConfigTemplates } from 'k8s/components/kaapi/kubeadm-config-templates/actions'
import { listKaapiMachineDeployments } from 'k8s/components/kaapi/machine-deployment/actions'
import { listKaapiMachines } from 'k8s/components/kaapi/machines/actions'
import { listKaapiOpenStackClusters } from 'k8s/components/kaapi/openstack-clusters/actions'
import { listkaapiOpenstackMachineTemplates } from 'k8s/components/kaapi/openstack-machine-templates/actions'
import { listKaapiSecrets } from 'k8s/components/kaapi/secrets/actions'
import {
  listKaapiMetricsNodes,
  listKaapiNodes,
} from 'k8s/components/kaapi/workload-clusters/nodes/actions'
import { listConfigMaps } from 'k8s/components/resources/config-maps/actions'
import { listCSIDrivers } from 'k8s/components/resources/csi-drivers/new-actions'
import {
  listCustomResourceDefinitions,
  listCustomResources,
} from 'k8s/components/resources/custom-resource-definitions/actions'
import { listNamespaces } from 'k8s/components/resources/namespaces/actions'
import { listPersistentVolumeClaims } from 'k8s/components/resources/persistent-volume-claims/new-actions'
import { listPersistentVolumes } from 'k8s/components/resources/persistent-volume/actions'
import { listSecrets } from 'k8s/components/resources/secrets/actions'
import {
  listClusterEvents,
  listStorageClasses,
} from 'k8s/components/resources/storage-classes/new-actions'
import { IDataKeys } from 'k8s/datakeys.model'
import { listFlavors } from 'openstack/components/flavors/actions'
import { listImages } from 'openstack/components/images/actions'
import {
  listHostAggregates,
  listHostNetworkConfigs,
  listHypervisors,
} from 'openstack/components/infrastructure/actions'
import { listAvailabilityZones } from 'openstack/components/infrastructure/availability-zones/actions'
import {
  listOctaviaListener,
  listOctaviaPools,
  listOctaviaPoolMembers,
  listOctaviaHealthMonitors,
  listLoadBalancers,
  listNetworkAvailability,
  listOctaviaFlavors,
  listNetworks as listOpenstackNetworks,
  listPorts as listOpenstackPorts,
  listSubnets as listOpenstackSubnets,
} from 'openstack/components/networks/actions'
import { listFloatingIps } from 'app/plugins/openstack/components/networks/floating-ips/actions'
import { listRouters as listOpenstackRouters } from 'openstack/components/networks/routers/actions'
import { listSecurityGroups } from 'openstack/components/networks/security-groups/actions'
import { listStacks } from 'openstack/components/orchestration/actions'
import { listSshKeys } from 'openstack/components/security/keys/actions'
import { listVolumeSnapshots as listOpenstackVolumeSnapshots } from 'openstack/components/storage/snapshots/actions'
import {
  listAllVolumes,
  listVolumes as listOpenstackVolumes,
  listVolumeTransfers,
  listAllVolumeTransfers,
} from 'openstack/components/storage/volumes/actions'
import {
  listAllVirtualMachines as listAllOpenstackVirtualMachines,
  listVirtualMachines as listOpenstackVirtualMachines,
} from 'openstack/components/vms/actions'
import { listServerGroups } from 'openstack/components/vms/server-groups/actions'
import { listStoragePools } from './plugins/openstack/components/storage/pools/actions'
import { listVolumeTypes } from './plugins/openstack/components/storage/volume-types/actions'
import { listPools } from 'app/plugins/openstack/components/networks/dns/pools/actions'
import { listZones } from 'app/plugins/openstack/components/networks/dns/zones/actions'
import { listRecords } from 'app/plugins/openstack/components/networks/dns/records/actions'
import { listDomains } from './plugins/account/components/userManagement/domains/actions'
// import { listAlerts, listSilences, listTimeSeriesAlerts } from 'k8s/components/alarms/new-actions'
// import { listCronjobs, listJobs } from 'k8s/components/cronjobs/actions'
// import { listDaemonSets } from 'k8s/components/daemon-sets/actions'
// import { listDeployments } from 'k8s/components/deployments/new-actions'
// import { listIngresses } from 'k8s/components/ingresses/new-actions'
// import {
//   listCloudProviders,
// loadAwsRegionInfo,
// } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
// import { listCapiAwsClusters } from 'app/plugins/infrastructure/components/clusters/aws/capi/actions'
// import {
//   listAwsAmis,
//   listCapiClusters,
// } from 'app/plugins/infrastructure/components/clusters/capi/actions'
// import { listAwsManagedControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/aws-managed-control-plane/actions'
// import { listNodeletControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/nodelet-control-plane/actions'
// import {
//   listAddonVersions,
//   listClusterAddons,
//   listClusterVersions,
//   listEksAddons,
//   listKubevirtAddons,
//   listMonitoringAddons,
//   listNetworkingAddons,
// } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
// import {
//   listClusters,
//   listSupportedRoleVersions,
// } from 'app/plugins/infrastructure/components/clusters/newActions'
// import {
//   listClusterAgents,
//   listImportedClusters,
// } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
// import { listK8sNodes, listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'

// import {
//   listIpAllocations,
//   listLiveMigrations,
//   listVirtualMachineInstances,
//   listVirtualMachinePresets,
//   listVirtualMachines,
// } from 'app/plugins/kubevirt/components/virtual-machines/new-actions'
// import { listAlertRules } from 'k8s/components/monitoring/new-actions'
// import { listHostNetworkTemplates } from 'k8s/components/networking/host-configs/actions'
// import { listNetworks } from 'k8s/components/networking/network/actions'
// import { listNetworkPlugins } from 'k8s/components/networking/plugins/actions'
// import { listPodMetrics } from 'k8s/components/pods/metrics/new-actions'
// import { listPods } from 'k8s/components/pods/new-actions'
// import { listPrometheusRules, listServiceAccounts } from 'k8s/components/prometheus/new-actions'
// import { listReplicaSets } from 'k8s/components/replica-sets/actions'

// import { listServices } from 'k8s/components/services/new-actions'
// import { listStatefulSets } from 'k8s/components/stateful-sets/actions'
// import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'

// import { listAwsMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-machine-pool/actions'
// import { listAwsMachineTemplates } from './plugins/infrastructure/components/clusters/capi/aws-machine-templates/actions'
// import { listAwsManagedMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-managed-machine-pool/actions'
// import {
//   listEksConfigs,
//   listEksConfigTemplates,
//   listNodeletConfigs,
//   listNodeletConfigTemplates,
// } from './plugins/infrastructure/components/clusters/capi/configs/actions'
// import { listCapiClusterEvents } from './plugins/infrastructure/components/clusters/capi/details/overview/events/actions'
// import { listCapiHosts } from './plugins/infrastructure/components/clusters/capi/details/overview/hosts/actions'
// import { listEksAmiTypes } from './plugins/infrastructure/components/clusters/capi/eks-ami-types/actions'
// import {
//   listAwsClusterRoleIdentities,
//   listAwsClusterStaticIdentities,
// } from './plugins/infrastructure/components/clusters/capi/identities/actions'
// import { listMachineDeployments } from './plugins/infrastructure/components/clusters/capi/machine-deployment/actions'
// import { listMachinePools } from './plugins/infrastructure/components/clusters/capi/machine-pool/actions'
// import { listClusterUpgradeJobs } from './plugins/infrastructure/components/clusters/capi/upgrade/actions'
// import { listEksVersions } from './plugins/infrastructure/components/versions/eks/actions'
// import {
//   listClusterInstanceTypes,
//   listInstanceTypes,
// } from './plugins/kubevirt/components/virtual-machines/instance-types/actions'

const listActionsByKey: Record<keyof IDataKeys, ListAction<keyof IDataKeys>> = {
  // AddonVersions: listAddonVersions,
  // AlertRules: listAlertRules,
  // Alerts: listAlerts,
  // AlertsTimeSeries: listTimeSeriesAlerts,
  // AllClusters: listClusters,
  // ClusterAddons: listClusterAddons,
  // ServiceAccounts: listServiceAccounts,
  // PrometheusRules: listPrometheusRules,
  // Silences: listSilences,
  // Clusters: listClusters,
  // Deployments: listDeployments,
  // ImportedClusters: listImportedClusters,
  // Ingresses: listIngresses,
  // Nodes: listNodes,
  // K8sNodes: listK8sNodes,
  // PodMetrics: listPodMetrics,
  // Pods: listPods,
  // SupportedRoleVersions: listSupportedRoleVersions,
  // EksVersions: listEksVersions,
  // AwsRegionInfo: loadAwsRegionInfo,
  // VirtualMachines: listVirtualMachines,
  // VirtualMachineInstances: listVirtualMachineInstances,
  // VirtualMachinePresets: listVirtualMachinePresets,
  // Networks: listNetworks,
  // DataVolumes: listDataVolumes,
  // CapiEvents: listCapiClusterEvents,
  // Cronjobs: listCronjobs,
  // Jobs: listJobs,
  // StatefulSets: listStatefulSets,
  // ReplicaSets: listReplicaSets,
  // DaemonSets: listDaemonSets,
  // MonitoringAddons: listMonitoringAddons,
  // KubevirtAddons: listKubevirtAddons,
  // NetworkingAddons: listNetworkingAddons,
  // ClusterAgents: listClusterAgents,
  // CapiClusters: listCapiClusters,
  // CapiAwsClusters: listCapiAwsClusters,
  // NodeletControlPlanes: listNodeletControlPlanes,
  // AwsAmis: listAwsAmis,
  // AwsManagedControlPlanes: listAwsManagedControlPlanes,
  // CapiHosts: listCapiHosts,
  // AwsMachineTemplates: listAwsMachineTemplates,
  // MachineDeployments: listMachineDeployments,
  // MachinePools: listMachinePools,
  // AwsMachinePools: listAwsMachinePools,
  // AwsManagedMachinePools: listAwsManagedMachinePools,
  // EksConfigs: listEksConfigs,
  // EksConfigTemplates: listEksConfigTemplates,
  // NodeletConfigs: listNodeletConfigs,
  // NodeletConfigTemplates: listNodeletConfigTemplates,
  // AwsClusterRoleIdentities: listAwsClusterRoleIdentities,
  // AwsClusterStaticIdentities: listAwsClusterStaticIdentities,
  // ClusterVersions: listClusterVersions,
  // LiveMigrations: listLiveMigrations,
  // ClusterUpgradeJobs: listClusterUpgradeJobs,
  // EksAmiTypes: listEksAmiTypes,
  // InstanceTypes: listInstanceTypes,
  // ClusterInstanceTypes: listClusterInstanceTypes,
  // EksAddons: listEksAddons,
  // IpAllocations: listIpAllocations,
  // NetworkPlugins: listNetworkPlugins,
  // HostNetworkTemplates: listHostNetworkTemplates,
  // CloudProviders: listCloudProviders,
  // KubeServices: listServices,
  // Services: listServices,
  Apps: listApps,
  AppsAvailableToCluster: listAppsAvailableToCluster,
  ApiEndpoints: null, // @todo
  ApiGroups: listApiGroups,
  ApiResources: listApiResources,
  AppDetails: null, // @todo
  AppVersions: null,
  Repositories: listRepositories,
  DeployedApps: listDeployedApps,
  RepositoriesForCluster: listRepositoriesForCluster,
  CloudProviderDetails: null, // @todo
  CloudProviderRegionDetails: null, // @todo
  ClusterRoleBindings: listClusterRoleBindings,
  ClusterRoles: listClusterRoles,
  Endpoints: null,
  PrometheusInstances: null, // @todo
  PrometheusServiceMonitors: null, // @todo
  PrometheusAlertManagers: null, // @todo
  StorageClasses: listStorageClasses,
  CSIDrivers: listCSIDrivers,
  PersistentVolumes: listPersistentVolumes,
  PersistentVolumeClaims: listPersistentVolumeClaims,
  Ssh: null, // @todo
  ServiceCatalog: null, // @todo
  DeployedAppDetails: null, // @todo
  Flavors: listFlavors, // @todo
  KubeRoles: listRoles,
  KubeServiceDetails: null, // @todo
  ManagementCredentials: listCredentials,
  ManagementGroups: listMngmGroups,
  ManagementGroupsMappings: listMngmGroupMappings,
  ManagementRoles: listMngmRoles,
  ManagementTenants: listTenants,
  ManagementTenantsRoleAssignments: listTenantRoleAssignments,
  ManagementUsers: listUsers,
  ManagementUsersRoleAssignments: listUserRoleAssignments,
  Namespaces: listNamespaces,
  Tenants: listTenants,
  PodTemplates: null,
  Regions: listRegions,
  ResMgrHosts: listResMgrHosts,
  RoleBindings: listRoleBindings,
  UserTenants: listUserTenants,
  Domains: listDomains,
  ConfigMaps: listConfigMaps,
  Users: listUsers,
  VirtualMachineDetails: null, // @todo
  Events: listClusterEvents,
  Secrets: listSecrets,
  Images: listImages,
  OpenstackVirtualMachines: listOpenstackVirtualMachines,
  AllOpenstackVirtualMachines: listAllOpenstackVirtualMachines,
  OpenstackNetworks: listOpenstackNetworks,
  OpenstackSubnets: listOpenstackSubnets,
  OpenstackPorts: listOpenstackPorts,
  OpenstackRouters: listOpenstackRouters,
  OpenstackVolumes: listOpenstackVolumes,
  AllOpenstackVolumes: listAllVolumes,
  VolumeTransfers: listVolumeTransfers,
  AllVolumeTransfers: listAllVolumeTransfers,
  OpenstackVolumeSnapshots: listOpenstackVolumeSnapshots,
  NetworkIpAvailability: listNetworkAvailability,
  ResmgrHosts: listResMgrHosts,
  Hypervisors: listHypervisors,
  SshKeys: listSshKeys,
  FloatingIps: listFloatingIps,
  SecurityGroups: listSecurityGroups,
  VolumeTypes: listVolumeTypes,
  HostAggregates: listHostAggregates,
  Stacks: listStacks,
  AvailabilityZones: listAvailabilityZones,
  ServerGroups: listServerGroups,
  StoragePools: listStoragePools,
  HostNetworkConfigs: listHostNetworkConfigs,
  DnsPools: listPools,
  DnsZones: listZones,
  DnsRecords: listRecords,
  LoadBalancers: listLoadBalancers,
  OctaviaFlavors: listOctaviaFlavors,
  OctaviaListeners: listOctaviaListener,
  OctaviaPools: listOctaviaPools,
  OctaviaPoolMembers: listOctaviaPoolMembers,
  OctaviaHealthMonitors: listOctaviaHealthMonitors,

  // Todo: create list actions for these
  KaapiClusters: listKaapiClusters,
  KaapiOpenStackClusters: listKaapiOpenStackClusters,
  KaapiHostedControlPlanes: listKaapiHostedControlPlanes,
  KaapiOpenStackMachineTemplates: listkaapiOpenstackMachineTemplates,
  KubeadmConfigTemplates: listkaapiKubeadmConfigTemplates,
  KaapiMachineDeployments: listKaapiMachineDeployments,
  KaapiMachines: listKaapiMachines,
  KaapiClusterAddons: null,
  KaapiTenants: null,
  KaapiClusterUpgradeJobs: listKaapiClusterUpgradeJobs,
  KaapiK8sClusterUpgrades: null,
  KaapiConfigMaps: listKaapiConfigMaps,
  KaapiNodes: listKaapiNodes,
  KaapiSecrets: listKaapiSecrets,
  KaapiMetricsNodes: listKaapiMetricsNodes,
  CustomResourceDefinitions: listCustomResourceDefinitions,
  CustomResources: listCustomResources,

  // TODO - Remove these
  CloudProviders: null,
  ImportedClusters: null,
  KubeServices: null,
  Nodes: null,
  PrometheusRules: null,
  ServiceAccounts: null,
  Services: null,
  Silences: null,
  SupportedRoleVersions: null,
}

export default listActionsByKey
