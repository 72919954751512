import ApiService from 'api-client/ApiService'
import config from 'app-config'
import { map, mergeLeft, pipe, propOr } from 'ramda'
import { cleanResource } from './utils'
import store from 'app/store'
import { sessionStoreKey } from 'core/session/sessionReducers'
import {
  APIGroupsResponse,
  APIResourcesResponse,
} from 'k8s/components/access-control/api-groups/model'
import { ConfigMapItem, ConfigMapsResponse } from 'k8s/components/resources/config-maps/models'
import {
  CustomResourceDefinitionItem,
  CustomResourceDefinitionsResponse,
  CustomResourcesItem,
  CustomResourcesResponse,
} from 'k8s/components/resources/custom-resource-definitions/model'
import { SecretItem, SecretsResponse } from 'k8s/components/resources/secrets/model'
import {
  GCluster,
  GetCluster,
  GetClusterClusterRoles,
  GetClusterNamespaces,
  GetClusterNamespacesItem,
  GetClusterRoles,
  IGenericClusterizedResponse,
  IGenericPayloadWithMetadata,
  IGenericResource,
  EventsResponse,
} from './capi.model'
import { trackApiMethodMetadata } from './helpers'
import {
  PersistentVolumeClaimsItem,
  PersistentVolumeClaimsResponse,
} from 'k8s/components/resources/persistent-volume-claims/model'
import { StorageClassesResponse } from 'k8s/components/resources/storage-classes/model'
import {
  PersistentVolumesResponse,
  PersistentVolumesItem,
} from 'k8s/components/resources/persistent-volume/model'
import { StorageClassesItem } from 'k8s/components/resources/storage-classes/model'
import { CSIDriver, CSIDriversResponse } from 'k8s/components/resources/csi-drivers/model'

// Workload Cluster Cluster Endpoints for kaapi workload clusters
enum CapiEndpoints {
  'NODES' = '/api/v1/nodes',
  'METRICSNODES' = '/apis/metrics.k8s.io/v1beta1/nodes',
  'PODS' = '/api/v1/pods',
  'NAMESPACES' = '/api/v1/namespaces',
}

const normalizeClusterizedResponse = <T>(
  clusterId: string,
  response: GCluster<T>,
): Array<IGenericClusterizedResponse<T>> =>
  pipe(
    propOr([], 'items'),
    map<any, any>(
      mergeLeft<any>({ clusterId }),
    ),
  )(response)

const normalizeClusterizedUpdate = (clusterId, response) => ({
  ...response,
  clusterId,
})

class Capi extends ApiService {
  public getClassName() {
    return 'kubernetes'
  }

  static apiMethodsMetadata = []

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get kaapiNamespace() {
    const state = store.getState()
    const { activeKaapiTenant } = state[sessionStoreKey]
    return activeKaapiTenant
  }

  // For Kaapi Workload Cluster - get the endpoint stored in the preferences
  get workloadClusterEndpoint() {
    const state = store.getState()
    const { username } = state[sessionStoreKey]
    const { preferences } = state
    return preferences[username]?.['k8sPluginGlobalParams']?.cluster_endpoint as string
  }

  convertResource = (clusterId) => <T extends IGenericPayloadWithMetadata>(
    item: T,
  ): IGenericResource<T> => ({
    ...item,
    clusterId,
    name: item.metadata.name,
    created: item.metadata.creationTimestamp,
    id: item.metadata.uid,
    ...(item.metadata.namespace ? { namespace: item.metadata.namespace } : {}),
  })

  @trackApiMethodMetadata({
    url: 'api',
    type: 'GET',
  })
  checkWorkloadClusterEndpoint = async () => {
    const url = `api`
    return await this.client.basicGet<any>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'checkHealth',
        useJWTToken: true,
      },
    })
  }

  // Worker Nodes
  @trackApiMethodMetadata({
    url: '/api/v1/nodes',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Nodes' },
  })
  getAllWorkerNodes = async () => {
    try {
      const url = CapiEndpoints.NODES
      const data = await this.client.basicGet<any>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getAllWorkerNodes',
          useJWTToken: true,
        },
      })
      return cleanResource()(data.items || [])
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/api/v1/nodes',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Nodes' },
  })
  getAllMetricsWorkerNodes = async () => {
    try {
      const url = CapiEndpoints.METRICSNODES
      const data = await this.client.basicGet<any>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getAllMetricsWorkerNodes',
          useJWTToken: true,
        },
      })
      return cleanResource()(data?.items || [])
    } catch (e) {
      console.log(e)
      return null
    }
  }

  // Pods
  @trackApiMethodMetadata({
    url: '/api/v1/pods',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Pods' },
  })
  getAllPods = async () => {
    const url = CapiEndpoints.PODS
    const data = await this.client.basicGet<any>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllPods',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  // Namespaces
  // @trackApiMethodMetadata({
  //   url: '/api/v1/namespaces',
  //   type: 'GET',
  //   error: { isClusterError: true, k8sResource: 'Namespaces' },
  // })
  // getAllNamespaces = async () => {
  //   const url = CapiEndpoints.NAMESPACES
  //   const data = await this.client.basicGet<any>({
  //     endpoint: this.workloadClusterEndpoint,
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getAllNamespaces',
  //       useJWTToken: true,
  //     },
  //   })
  //   return cleanResource()(data.items || [])
  // }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  getAllNamespaces = async (clusterId) => {
    const url = `api/v1/namespaces`
    try {
      const data = await this.client.basicGet<GetClusterNamespaces>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getClusterNamespaces',
          useJWTToken: true,
        },
      })
      return data.items.map(this.convertResource(clusterId))
    } catch (e) {
      console.log(e)
      return []
    }
  }
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  createNamespace = async (clusterId, body) => {
    const url = `api/v1/namespaces`

    const raw = await this.client.basicPost<GetClusterNamespacesItem>({
      url,
      body,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createNamespace',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(raw)
  }
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace',
    type: 'DELETE',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  deleteNamespace = async (clusterId, namespaceName) => {
    const url = `api/v1/namespaces/${namespaceName}`

    const data = await this.client.basicDelete<GetClusterNamespacesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteNamespace',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  /* RBAC */
  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/roles',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  getRoles = async (clusterId) => {
    const url = `apis/rbac.authorization.k8s.io/v1/roles`
    try {
      const data = await this.client.basicGet<GetClusterRoles>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getRoles',
          useJWTToken: true,
        },
      })
      return data.items.map(this.convertResource(clusterId))
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  createRole = async (clusterId, namespace, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles`
    const response = await this.client.basicPost({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createRole',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:role',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'role'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  updateRole = async (clusterId, namespace, name, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles/${name}`
    const response = await this.client.basicPut({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateRole',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:role',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'role'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  deleteRole = async (clusterId, namespace, name) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles/${name}`
    return this.client.basicDelete({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRole',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterroles',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  getClusterRoles = async (clusterId) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterroles`
    try {
      const response = await this.client.basicGet<GetClusterClusterRoles>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getClusterRoles',
          useJWTToken: true,
        },
      })
      return normalizeClusterizedResponse(clusterId, response)
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterroles',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  createClusterRole = async (clusterId, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterroles`
    const response = await this.client.basicPost({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterRole',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterroles/:clusterRole',
    type: 'PUT',
    params: ['clusterId', 'clusterRole'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  updateClusterRole = async (clusterId, name, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterroles/${name}`
    const response = await this.client.basicPut({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterRole',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterroles/:clusterRole',
    type: 'DELETE',
    params: ['clusterId', 'clusterRole'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  deleteClusterRole = async (clusterId, name) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterroles/${name}`
    return this.client.basicDelete({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterRole',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/rolebindings',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  getRoleBindings = async (clusterId) => {
    try {
      const url = `apis/rbac.authorization.k8s.io/v1/rolebindings`
      const data = await this.client.basicGet<GetCluster>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getRoleBindings',
          useJWTToken: true,
        },
      })
      return data.items.map(this.convertResource(clusterId)) || []
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  createRoleBinding = async (clusterId, namespace, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings`
    const response = await this.client.basicPost({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createRoleBinding',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:roleBinding',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'roleBinding'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  updateRoleBinding = async (clusterId, namespace, name, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings/${name}`
    const response = await this.client.basicPut({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateRoleBinding',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:roleBinding',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'roleBinding'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  deleteRoleBinding = async (clusterId, namespace, name) => {
    const url = `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings/${name}`
    return this.client.basicDelete({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRoleBinding',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  getClusterRoleBindings = async (clusterId) => {
    try {
      const url = `apis/rbac.authorization.k8s.io/v1/clusterrolebindings`
      const response = await this.client.basicGet<GetCluster>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getClusterClusterRoleBindings',
          useJWTToken: true,
        },
      })
      return normalizeClusterizedResponse(clusterId, response) || []
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  createClusterRoleBinding = async (clusterId, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterrolebindings`
    const response = await this.client.basicPost({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterClusterRoleBinding',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:clusterRoleBinding',
    type: 'PUT',
    params: ['clusterId', 'clusterRoleBinding'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  updateClusterRoleBinding = async (clusterId, name, body) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterrolebindings/${name}`
    const response = await this.client.basicPut({
      endpoint: this.workloadClusterEndpoint,
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterClusterRoleBinding',
        useJWTToken: true,
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: 'apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:clusterRoleBinding',
    type: 'DELETE',
    params: ['clusterId', 'clusterRoleBinding'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  deleteClusterRoleBinding = async (clusterId, name) => {
    const url = `apis/rbac.authorization.k8s.io/v1/clusterrolebindings/${name}`
    return this.client.basicDelete({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterClusterRoleBinding',
        useJWTToken: true,
      },
    })
  }
  // API Resources
  @trackApiMethodMetadata({
    url: 'apis',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'APIGroupList' },
  })
  getApiGroupList = async () => {
    const url = `apis`
    return this.client.basicGet<APIGroupsResponse>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getApiGroupList',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/:apiGroup',
    type: 'GET',
    params: ['apiGroup'],
    error: { isClusterError: true, k8sResource: 'APIGroup' },
  })
  getApiResourcesList = async (config) => {
    const { apiGroup } = config
    const url = `apis/${apiGroup}`
    return this.client.basicGet<APIResourcesResponse>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getApiResourcesList',
        useJWTToken: true,
      },
    })
  }
  @trackApiMethodMetadata({
    url: 'api/v1/configmaps',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  getConfigMaps = async (clusterId, namespace?: string) => {
    const url = namespace ? `api/v1/namespaces/${namespace}/configmaps` : `api/v1/configmaps`
    const data = await this.client.basicGet<ConfigMapsResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getConfigMaps',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/configmaps/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  getConfigMap = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/configmaps/${name}`
    try {
      const data = await this.client.basicGet<ConfigMapItem>({
        url,
        endpoint: this.workloadClusterEndpoint,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getConfigMap',
          extractNestedData: false,
          useJWTToken: true,
        },
      })
      return data
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/configmaps',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  createConfigMap = async (clusterId, namespace, body) => {
    const url = `api/v1/namespaces/${namespace}/configmaps`
    const data = await this.client.basicPost<ConfigMapItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createConfigMap',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/configmaps/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'ConfigMap',
    },
  })
  updateConfigMap = async ({ clusterId, namespace, name, body }) => {
    const url = `api/v1/namespaces/${namespace}/configmaps/${name}`
    const data = await this.client.basicPut<ConfigMapItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateConfigMap',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/configmaps/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  patchConfigMap = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = `api/v1/namespaces/${namespace}/configmaps/${name}`
    const data = await this.client.basicPatch<any>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchConfigMap',
        extractNestedData: false,
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/configmaps/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  deleteConfigMap = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/configmaps/${name}`
    return this.client.basicDelete({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteConfigMap',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'api/v1/secrets',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  getSecrets = async (clusterId, namespace?: string) => {
    const url = namespace ? `api/v1/namespaces/${namespace}/secrets` : `api/v1/secrets`
    const data = await this.client.basicGet<SecretsResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSecrets',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/secrets/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  getSecret = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/secrets/${name}`
    const data = await this.client.basicGet<SecretItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSecret',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/secrets',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  createSecret = async (clusterId, namespace, body) => {
    const url = `api/v1/namespaces/${namespace}/secrets`
    const data = await this.client.basicPost<SecretItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSecret',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/secrets/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  updateSecret = async ({ clusterId, namespace, name, body }) => {
    const url = `api/v1/namespaces/${namespace}/secrets/${name}`
    const data = await this.client.basicPut<SecretItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateSecret',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/secrets/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  patchSecret = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = `api/v1/namespaces/${namespace}/secrets/${name}`
    const data = await this.client.basicPatch<any>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchSecret',
        extractNestedData: false,
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/secrets/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  deleteSecret = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/secrets/${name}`
    return this.client.basicDelete({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSecret',
        useJWTToken: true,
        // extractNestedData: false,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/apiextensions.k8s.io/v1/customresourcedefinitions',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  getCustomResourceDefinitions = async (clusterId) => {
    const url = `apis/apiextensions.k8s.io/v1/customresourcedefinitions`
    const data = await this.client.basicGet<CustomResourceDefinitionsResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCustomResourceDefinitions',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: `apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name`,
    type: 'GET',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  getCustomResourceDefinition = async (clusterId, name) => {
    const url = `apis/apiextensions.k8s.io/v1/customresourcedefinitions/${name}`
    const data = await this.client.basicGet<CustomResourceDefinitionItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCustomResourceDefinition',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url: `apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name`,
    type: 'PUT',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  updateCustomResourceDefinition = async ({ clusterId, name, body }) => {
    const url = `apis/apiextensions.k8s.io/v1/customresourcedefinitions/${name}`
    const data = await this.client.basicPut<CustomResourceDefinitionItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCustomResourceDefinition',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: `apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name`,
    type: 'PATCH',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  patchCustomResourceDefinition = async ({
    clusterId,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = `apis/apiextensions.k8s.io/v1/customresourcedefinitions/${name}`
    const data = await this.client.basicPatch<CustomResourceDefinitionItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchCustomResourceDefinition',
        extractNestedData: false,
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  createCustomResourceDefinition = async (clusterId, body) => {
    const url = `/apis/apiextensions.k8s.io/v1/customresourcedefinitions`
    const data = await this.client.basicPost<CustomResourceDefinitionItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCustomResourceDefinition',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  deleteCustomResourceDefinition = async (clusterId, name) => {
    const url = `/apis/apiextensions.k8s.io/v1/customresourcedefinitions/${name}`
    return this.client.basicDelete({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCustomResourceDefinition',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: `/apis/:group/:version/:pluralName`,
    type: 'GET',
    params: ['clusterId', 'group', 'version', 'pluralName'],
    error: { isClusterError: true, k8sResource: 'CustomResourceDefinition' },
  })
  getCustomResources = async (clusterId, group, version, pluralName) => {
    const url = `/apis/${group}/${version}/${pluralName}`
    const data = await this.client.basicGet<CustomResourcesResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCustomResources',
        extractNestedData: false,
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: 'apis/:group/:version/namespaces/:namespace/:pluralName/:name',
    type: 'GET',
    params: ['clusterId', 'group', 'version', 'pluralName', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResource' },
  })
  getCustomResourceByName = async ({ group, version, pluralName, namespace, name }) => {
    const url =
      namespace && namespace !== 'default'
        ? `apis/${group}/${version}/namespaces/${namespace}/${pluralName}/${name}`
        : `apis/${group}/${version}/${pluralName}/${name}`
    const data = await this.client.basicGet<any>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCustomResourceByName',
        useJWTToken: true,
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url: 'apis/:group/:version/namespaces/:namespace/:pluralName/:name',
    type: 'PATCH',
    params: ['clusterId', 'group', 'version', 'pluralName', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResource' },
  })
  patchCustomResource = async ({
    clusterId,
    group,
    version,
    pluralName,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url =
      namespace && namespace !== 'default'
        ? `apis/${group}/${version}/namespaces/${namespace}/${pluralName}/${name}`
        : `apis/${group}/${version}/${pluralName}/${name}`
    const data = await this.client.basicPatch<CustomResourcesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchCustomResource',
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'apis/:group/:version/namespaces/:namespace/:pluralName/:name',
    type: 'PUT',
    params: ['clusterId', 'group', 'version', 'pluralName', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResource' },
  })
  updateCustomResource = async ({
    clusterId,
    group,
    version,
    pluralName,
    namespace,
    name,
    body,
  }) => {
    const url =
      namespace && namespace !== 'default'
        ? `apis/${group}/${version}/namespaces/${namespace}/${pluralName}/${name}`
        : `apis/${group}/${version}/${pluralName}/${name}`
    const data = await this.client.basicPut<CustomResourcesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCustomResource',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'apis/:group/:version/namespaces/:namespace/:pluralName/:name',
    type: 'DELETE',
    params: ['clusterId', 'group', 'version', 'pluralName', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'CustomResource' },
  })
  deleteCustomResource = async ({ clusterId, group, version, pluralName, namespace, name }) => {
    const url =
      namespace && namespace !== 'default'
        ? `apis/${group}/${version}/namespaces/${namespace}/${pluralName}/${name}`
        : `apis/${group}/${version}/${pluralName}/${name}`
    return this.client.basicDelete<CustomResourcesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCustomResource',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: 'apis/:group/:version/namespaces/:namespace/:pluralName',
    type: 'POST',
    params: ['clusterId', 'group', 'version', 'pluralName', 'namespace', 'body'],
    error: { isClusterError: true, k8sResource: 'CustomResource' },
  })
  createCustomResource = async ({ clusterId, group, version, pluralName, namespace, body }) => {
    const url =
      namespace && namespace !== 'default'
        ? `apis/${group}/${version}/namespaces/${namespace}/${pluralName}`
        : `apis/${group}/${version}/${pluralName}`
    const data = await this.client.basicPost<CustomResourcesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCustomResource',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumeclaims',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PersistentVolumeClaim' },
  })
  getPersistentVolumeClaims = async (clusterId) => {
    const url = `api/v1/persistentvolumeclaims`
    try {
      const data = await this.client.basicGet<PersistentVolumeClaimsResponse>({
        url,
        endpoint: this.workloadClusterEndpoint,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getPersistentVolumeClaims',
          useJWTToken: true,
        },
      })
      return data.items.map(this.convertResource(clusterId))
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'PersistentVolumeClaim' },
  })
  getPersistentVolumeClaim = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/persistentvolumeclaims/${name}`
    const data = await this.client.basicGet<PersistentVolumeClaimsItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPersistentVolumeClaim',
        useJWTToken: true,
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/persistentvolumeclaims',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolumeClaim',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createPersistentVolumeClaim = async (clusterId, namespace, body) => {
    const url = `api/v1/namespaces/${namespace}/persistentvolumeclaims`
    const data = await this.client.basicPost<PersistentVolumeClaimsItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPersistentVolumeClaim',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolumeClaim',
    },
  })
  updatePersistentVolumeClaim = async ({ clusterId, namespace, name, body }) => {
    const url = `api/v1/namespaces/${namespace}/persistentvolumeclaims/${name}`

    const data = await this.client.basicPut<PersistentVolumeClaimsItem>({
      url,
      endpoint: this.workloadClusterEndpoint,

      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePersistentVolumeClaim',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolumeClaim',
    },
  })
  patchPersistentVolumeClaim = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = `api/v1/namespaces/${namespace}/persistentvolumeclaims/${name}`
    const data = await this.client.basicPatch<PersistentVolumeClaimsItem>({
      url,
      endpoint: this.workloadClusterEndpoint,

      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchPersistentVolumeClaim',
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolumeClaim',
      k8sResourceAccessor: 'params.name',
    },
  })
  deletePersistentVolumeClaim = async (clusterId, namespace, name) => {
    const url = `api/v1/namespaces/${namespace}/persistentvolumeclaims/${name}`
    const data = await this.client.basicDelete<PersistentVolumeClaimsItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePersistentVolumeClaim',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  //StorageClasses
  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/storageclasses',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  getClusterStorageClasses = async (clusterId) => {
    const url = `apis/storage.k8s.io/v1/storageclasses`
    const data = await this.client.basicGet<StorageClassesResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterStorageClasses',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }
  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PersistentVolume' },
  })
  getPersistentVolumes = async (clusterId) => {
    try {
      const url = `api/v1/persistentvolumes`
      const data = await this.client.basicGet<PersistentVolumesResponse>({
        url,
        endpoint: this.workloadClusterEndpoint,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getPersistentVolumes',
          useJWTToken: true,
        },
      })
      return data.items && data.items.map(this.convertResource(clusterId))
    } catch (e) {
      console.log(e)
      return []
    }
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes/:name',
    type: 'GET',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'PersistentVolume' },
  })
  getPersistentVolume = async (name) => {
    const url = `api/v1/persistentvolumes/${name}`
    const data = await this.client.basicGet<PersistentVolumesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPersistentVolume',
        useJWTToken: true,
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes',
    type: 'POST',
    params: ['clusterId'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolume',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createPersistentVolume = async (clusterId, body) => {
    const url = `api/v1/persistentvolumes`
    const data = await this.client.basicPost<PersistentVolumesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPersistentVolume',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes/:name',
    type: 'PUT',
    params: ['clusterId', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolume',
    },
  })
  updatePersistentVolume = async ({ clusterId, name, body }) => {
    const url = `api/v1/persistentvolumes/${name}`
    const data = await this.client.basicPut<PersistentVolumesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePersistentVolume',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes/:name',
    type: 'PATCH',
    params: ['clusterId', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolume',
    },
  })
  patchPersistentVolume = async ({
    clusterId,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = `api/v1/persistentvolumes/${name}`
    const data = await this.client.basicPatch<PersistentVolumesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchPersistentVolume',
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'api/v1/persistentvolumes/:name',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'PersistentVolume',
      k8sResourceAccessor: 'params.name',
    },
  })
  deletePersistentVolume = async (clusterId, name) => {
    const url = `api/v1/persistentvolumes/${name}`
    const data = await this.client.basicDelete<PersistentVolumesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePersistentVolume',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }
  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/storageclasses/:name',
    type: 'GET',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  getClusterStorageClassByName = async (clusterId, name) => {
    const url = `apis/storage.k8s.io/v1/storageclasses/${name}`
    const data = await this.client.basicGet<StorageClassesResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterStorageClassByName',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/storageclasses/:name',
    type: 'PUT',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  updateClusterStorageClass = async (clusterId, name, body) => {
    const url = `apis/storage.k8s.io/v1/storageclasses/${name}`
    const data = await this.client.basicPut<StorageClassesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterStorageClasses',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/storageclasses',
    type: 'POST',
    params: ['clusterId'],
    error: {
      isClusterError: true,
      k8sResource: 'StorageClass',
      k8sResourceAccessor: 'params.name',
    },
  })
  createStorageClass = async (clusterId, body) => {
    const url = `apis/storage.k8s.io/v1/storageclasses`
    const data = await this.client.basicPost<StorageClassesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createStorageClass',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/storageclasses/:storageClass',
    type: 'DELETE',
    params: ['clusterId', 'storageClass'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  deleteStorageClass = async (clusterId, name) => {
    const url = `apis/storage.k8s.io/v1/storageclasses/${name}`
    const data = await this.client.basicDelete<StorageClassesItem>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteStorageClass',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }
  @trackApiMethodMetadata({
    url: 'apis/events.k8s.io/v1/events',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Events' },
  })
  getClusterEvents = async (clusterId) => {
    const url = `apis/events.k8s.io/v1/events`
    const data = await this.client.basicGet<EventsResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterEvents',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: 'apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Events' },
  })
  deleteClusterEvent = async (clusterId, namespace, eventName) => {
    const url = `apis/events.k8s.io/v1/namespaces/${namespace}/events/${eventName}`
    return this.client.basicDelete({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterEvent',
        useJWTToken: true,
      },
    })
  }
  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/csidrivers',
    type: 'GET',
    params: ['clusterId'],
  })
  getCSIDrivers = async (clusterId) => {
    const url = `apis/storage.k8s.io/v1/csidrivers`
    const data = await this.client.basicGet<CSIDriversResponse>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCSIDrivers',
        useJWTToken: true,
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: 'apis/storage.k8s.io/v1/csidrivers/:name',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CSI Driver' },
  })
  deleteCSIDriver = async (clusterId, name) => {
    const url = `apis/storage.k8s.io/v1/csidrivers/${name}`
    const data = await this.client.basicDelete<CSIDriver>({
      url,
      endpoint: this.workloadClusterEndpoint,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCSIDriver',
        useJWTToken: true,
      },
    })
    return this.convertResource(clusterId)(data)
  }
}

export default Capi
