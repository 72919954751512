import ApiService from 'api-client/ApiService'

class Octavia extends ApiService {
  public getClassName() {
    return 'octavia'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('octavia', 'public')
  }

  async getLoadBalancers() {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/loadbalancers`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getLoadBalancers',
      },
    })
    return response.loadbalancers
  }

  async getLoadBalancer(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/loadbalancers/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getLoadBalancer',
      },
    })
    return response.loadbalancer
  }

  async getListeners() {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/listeners`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getListener',
      },
    })
    return response.listeners
  }

  async getListener(id: string) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/listeners/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getListener',
      },
    })
    return response.listener
  }

  async getPools() {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/pools`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPool',
      },
    })
    return response.pools
  }

  async getPool(id: string) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/pools/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPool',
      },
    })
    return response.pool
  }

  async getPoolMembers(poolId: string) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/pools/${poolId}/members`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPoolMember',
      },
    })
    const members = response.members.map((member) => {
      return {
        ...member,
        poolId,
      }
    })
    return members
  }

  async deleteMember(poolId, id) {
    return this.client.basicDelete<any>({
      url: `/v2/lbaas/pools/${poolId}/members/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePools',
      },
    })
  }

  async getPoolMember(poolId: string, memberId: string) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/pools/${poolId}/members/${memberId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPoolMember',
      },
    })
    const member = response.member
    return {
      ...member,
      poolId,
    }
  }

  async getHealthMonitors() {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/healthmonitors`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHealthMonitor',
      },
    })
    return response.healthmonitors
  }

  async getHealthMonitor(id: string) {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/healthmonitors/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHealthMonitor',
      },
    })
    return response.healthmonitor
  }

  async deleteHealthMonitor(id) {
    return this.client.basicDelete<any>({
      url: `/v2/lbaas/healthmonitors/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteHealthMonitor',
      },
    })
  }

  async getOctaviaFlavors() {
    const response = await this.client.basicGet<any>({
      url: `/v2/lbaas/flavors`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOctaviaFlavors',
      },
    })
    return response.flavors
  }

  async createLoadBalancer(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2/lbaas/loadbalancers`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createLoadBalancer',
      },
    })
    return response.loadbalancer
  }

  async createListener(body: any) {
    const response = await this.client.basicPost<any>({
      url: `/v2/lbaas/listeners`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createListener',
      },
    })
    return response.listener
  }

  async deleteListener(id) {
    return this.client.basicDelete<any>({
      url: `/v2/lbaas/listeners/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteListener',
      },
    })
  }

  async createPool(body: any) {
    const response = await this.client.basicPost<any>({
      url: `/v2/lbaas/pools`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPool',
      },
    })
    return response.pool
  }

  async deletePools(id) {
    return this.client.basicDelete<any>({
      url: `/v2/lbaas/pools/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePools',
      },
    })
  }

  async addMemberToPool(poolId: string, body: any) {
    const response = await this.client.basicPost<any>({
      url: `/v2/lbaas/pools/${poolId}/members`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addMemberToPool',
      },
    })
    return response.member
  }

  async createHealthMonitor(body: any) {
    const response = await this.client.basicPost<any>({
      url: `/v2/lbaas/healthmonitors`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createHealthMonitor',
      },
    })
    return response.healthmonitor
  }

  async deleteLoadBalancer(id) {
    return this.client.basicDelete<any>({
      url: `/v2/lbaas/loadbalancers/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteLoadBalancer',
      },
    })
  }

  async updateLoadBalancer({ id, body }) {
    const url = `/v2/lbaas/loadbalancers/${id}`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateLoadBalancer',
      },
    })
    return response.loadbalancer
  }
}

export default Octavia
