import ApiService from 'api-client/ApiService'
import config from 'app-config'
import Cookies from 'js-cookie'
import { getIDPName } from './helpers'

class Hagrid extends ApiService {
  public getClassName() {
    return 'hagrid'
  }

  protected async getEndpoint() {
    let apiEndpoint = config.apiHost
    const serviceEndpoint = await this.client.keystone.getServicesEndpointForTypeAndRegion(
      'keystone',
      'public',
      'Infra',
    )
    if (serviceEndpoint) {
      apiEndpoint = new URL(serviceEndpoint).origin
    }
    return Promise.resolve(apiEndpoint)
  }

  get baseUrl(): string {
    return `/hagrid`
  }

  getSsoDetails = async () => {
    return this.client.rawGet({
      url: `${this.baseUrl}/sso`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSsoDetails',
      },
    })
  }

  getSsoConfig = async () => {
    return this.client.basicGet({
      url: `${this.baseUrl}/ssoconfig/${getIDPName()}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSsoConfig',
      },
    })
  }

  createSsoConfig = async (body) => {
    return this.client.basicPost({
      url: `${this.baseUrl}/ssoconfig`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSsoConfig',
      },
    })
  }

  deleteSsoConfig = async () => {
    return this.client.basicDelete({
      url: `${this.baseUrl}/ssoconfig/${getIDPName()}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSsoConfig',
      },
    })
  }
}

export default Hagrid
