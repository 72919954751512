import ApiService from 'api-client/ApiService'

class Designate extends ApiService {
  public getClassName() {
    return 'designate'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('designate', 'public')
  }

  async fetchAllData(initialUrl, methodName, dnsResource) {
    let allData = []
    let url = initialUrl

    while (url) {
      const response = await this.client.basicGet<any>({
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: methodName,
        },
      })

      if (!response || !response[dnsResource]) {
        throw new Error('Error fetching recordsets')
      }

      allData = allData.concat(response[dnsResource])

      // Check if there's a next page
      const nextUrl = response.links?.next
      if (nextUrl) {
        const urlObj = new URL(nextUrl)
        const marker = urlObj.searchParams.get('marker')
        url = marker ? `v2/${dnsResource}?marker=${marker}` : null
      } else {
        url = null
      }
    }

    return allData
  }

  //Pools
  async getPools() {
    const response = await this.client.basicGet<any>({
      url: `v2/pools`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPools',
      },
    })
    return response.pools
  }

  async createPool(body) {
    const response = await this.client.basicPost<any>({
      url: `v2/pools`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPool',
      },
    })
    return response.pool
  }

  async updatePool(poolId, body) {
    const response = await this.client.basicPut<any>({
      url: `v2/pools/${poolId}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePool',
      },
    })
    return response.pool
  }

  async deletePool(poolId) {
    await this.client.basicDelete<any>({
      url: `v2/pools/${poolId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePool',
      },
    })
  }

  //Zones
  async getZones() {
    let allZones = []
    const url = `v2/zones`

    allZones = await this.fetchAllData(url, 'getZones', 'zones')

    return allZones
  }

  async createZone(body) {
    const response = await this.client.basicPost<any>({
      url: `v2/zones`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createZone',
      },
    })
    return response.zone
  }

  async updateZone(zoneId, body) {
    const response = await this.client.basicPatch<any>({
      url: `v2/zones/${zoneId}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateZone',
      },
    })
    return response.zone
  }

  async deleteZone({ zoneId }) {
    await this.client.basicDelete<any>({
      url: `v2/zones/${zoneId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteZone',
      },
    })
  }

  //Records
  async getRecords() {
    let allRecordsets = []
    const url = `v2/recordsets`

    allRecordsets = await this.fetchAllData(url, 'getRecords', 'recordsets')

    return allRecordsets
  }

  async createRecord({ zoneId, body }) {
    const response = await this.client.basicPost<any>({
      url: `v2/zones/${zoneId}/recordsets`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createRecord',
      },
    })
    return response.recordset
  }

  async updateRecord({ zoneId, recordId, body }) {
    const response = await this.client.basicPut<any>({
      url: `v2/zones/${zoneId}/recordsets/${recordId}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateRecord',
      },
    })
    return response.recordset
  }

  async deleteRecord({ zoneId, recordId }) {
    await this.client.basicDelete<any>({
      url: `v2/zones/${zoneId}/recordsets/${recordId}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRecord',
      },
    })
  }
}

export default Designate
