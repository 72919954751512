import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import ResourceUsageTables from '../ResourceUsageTables'
import React from 'react'

export interface IResourceUsage {
  compute: any
  memory: any
  disk: any
  grafanaLink: string
}

export default function StatsCell({ item, value: usage }: GridCellProps<unknown, IResourceUsage>) {
  return <ResourceUsageTables usage={usage} />
}
