/* eslint-disable max-len */
import ApiService from 'api-client/ApiService'
import { getApiUrl, trackApiMethodMetadata } from 'api-client/helpers'

import store from 'app/store'
import { createUrlWithQueryString } from 'core/plugins/route'
import { sessionStoreKey } from 'core/session/sessionReducers'
import DataKeys from 'k8s/DataKeys'
import { map, mergeLeft, pipe, propOr } from 'ramda'
import { ensureArray, keyValueArrToObj } from 'utils/fp'
import uuid from 'uuid'
import {
  AlertManagerAlert,
  ClusterElement,
  GCluster,
  GetAddonVersions,
  GetCloudProvider,
  GetClusterDeployments,
  GetClusterDeploymentsItem,
  GetClusterKubeServices,
  GetClusterKubeServicesItem,
  GetClusterPods,
  GetClusterPodsItem,
  GetKubernetesVersion,
  GetPrometheusAlertRules,
  GetPrometheusAlerts,
  GetPrometheusAlertsOverTime,
  IGenericClusterizedResponse,
  IGenericPayloadWithMetadata,
  IGenericResource,
  Node,
  SupportedRoleVersions,
} from './qbert.model'

// import {
//   AwsCloudProviderRegionDetails,
//   AzureCloudProviderRegionDetails,
//   CloudProviderDetailsResponse,
//   CreateCloudProviderResponse,
// } from 'app/plugins/infrastructure/components/cloudProviders/model'
// import {
//   ClusterAddon,
//   ClusterAddons,
// } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
// import {
//   ClusterAgentsResponse,
//   ImportedCluster,
// } from 'app/plugins/infrastructure/components/importedClusters/model'
// import { K8SNodeResponse } from 'app/plugins/infrastructure/components/nodes/model'
// import { IRbacAPIGroup } from 'k8s/components/access-control/api-groups/model'
// import { IAlertOverTime } from 'k8s/components/alarms/model'
// import { CronjobItem, CronjobResponse } from 'k8s/components/cronjobs/cronjob-model'
// import { JobItem, JobsResponse } from 'k8s/components/cronjobs/job-model'
// import { DaemonSet, GetDaemonSetsReponse } from 'k8s/components/daemon-sets/models'
// import { IngressesResponse } from 'k8s/components/ingresses/model'
// import {
//   HostNetworkTemplate,
//   HostNetworkTemplatesResponse,
// } from 'k8s/components/networking/host-configs/model'
// import {
//   NetworkAttachmentDefinitionsResponse,
//   NetworksItem,
// } from 'k8s/components/networking/network/model'
// import { NetworkPluginItem, NetworkPluginsResponse } from 'k8s/components/networking/plugins/model'
// import { PodMetricsResponse } from 'k8s/components/pods/metrics/model'
// import { Pod } from 'k8s/components/pods/model'
// import { ReplicaSetsItem, ReplicaSetsResponse } from 'k8s/components/replica-sets/model'
// import { StatefulSet, StatefulSetResponse } from 'k8s/components/stateful-sets/model'
// import { DataVolumesItem, DataVolumesResponse } from 'k8s/components/storage/data-volumes/model'
// import { convertVolumeTypeToApiParam } from 'app/plugins/kubevirt/components/virtual-machines/helpers'
// import {
//   ClusterInstanceTypesResponse,
//   IClusterInstanceType,
// } from 'app/plugins/kubevirt/components/virtual-machines/instance-types/cluster-instance-types-model'
// import {
//   IInstanceType,
//   InstanceTypesResponse,
// } from 'app/plugins/kubevirt/components/virtual-machines/instance-types/instance-types-model'
// import { IPAllocationsResponse } from 'app/plugins/kubevirt/components/virtual-machines/ip-allocations-model'
// import { LiveMigrationsResponse } from 'app/plugins/kubevirt/components/virtual-machines/live-migrations/model'
// import {
//   GetVirtualMachineDetails,
//   GetVirtualMachines,
//   IVirtualMachine,
// } from 'app/plugins/kubevirt/components/virtual-machines/model'
// import { VirtualMachineInstancePresetsResponse } from 'app/plugins/kubevirt/components/virtual-machines/presets-model'
// import {
//   GetVirtualMachineInstancesResponse,
//   IVirtualMachineInstance,
// } from 'app/plugins/kubevirt/components/virtual-machines/vmi-model'

type AlertManagerRaw = Omit<AlertManagerAlert, 'clusterId' | 'id'>

// TODO: Fix these typings
const normalizeClusterizedResponse = <T>(
  clusterId: string,
  response: GCluster<T>,
): Array<IGenericClusterizedResponse<T>> =>
  pipe(
    propOr([], 'items'),
    map<any, any>(
      mergeLeft<any>({ clusterId }),
    ),
  )(response)

const normalizeClusterizedUpdate = (clusterId, response) => ({
  ...response,
  clusterId,
})

// const normalizeImportedClusters = (apiResponse: GCluster<ImportedCluster>) => {
//   const clusters = apiResponse.items
//   const normalizedClusters = clusters.reduce((accum, cluster) => {
//     const clusterName = cluster?.metadata?.name
//     if (cluster?.spec?.displayName === 'do-not-delete-stub-eco-cluster') return accum
//     accum.push({ ...cluster, uuid: clusterName })
//     return accum
//   }, [])
//   return normalizedClusters
// }

/* eslint-disable camelcase */
class Qbert extends ApiService {
  public getClassName() {
    return 'qbert'
  }

  static apiMethodsMetadata = []

  // cachedEndpoint = ''

  protected async getEndpoint() {
    const endpoint = await this.client.keystone.getServiceEndpoint('qbert', 'admin')
    const mappedEndpoint = endpoint?.replace(/v(1|2|3)$/, `v3`)

    // Certain operations like column renderers from ListTable need to prepend the Qbert URL to links
    // sent from the backend.  But getting the endpoint is an async operation so we need to make an
    // sync version.  In theory this should always be set since keystone must get the service
    // catalog before any Qbert API calls are made.
    // this.cachedEndpoint = mappedEndpoint
    return mappedEndpoint
  }

  // scopedEnpointPath = () => this.client.activeProjectId
  // For Kaapi Workload Cluster - get the endpoint stored in the preferences
  get workloadClusterEndpoint() {
    const state = store.getState()
    const { username } = state[sessionStoreKey]
    const { preferences } = state
    return preferences[username]?.['k8sPluginGlobalParams']?.cluster_endpoint as string
  }

  monocularBaseUrl = async () => {
    return this.client.keystone.getServiceEndpoint('monocular', 'public')
  }

  clusterBaseUrl = (clusterId) => `/clusters/${clusterId}/k8sapi/api/v1`

  // clusterMonocularBaseUrl = (clusterId, version = 'v1') =>
  //   pathJoin(
  //     this.clusterBaseUrl(clusterId),
  //     'namespaces',
  //     'kube-system',
  //     'services',
  //     'monocular-api-svc:80',
  //     'proxy',
  //     version || '/',
  //   )

  /* Cloud Providers */
  @trackApiMethodMetadata({ url: '/cloudProviders', type: 'GET' })
  getCloudProviders = async () => {
    const url = `/cloudProviders`
    return this.client.basicGet<GetCloudProvider[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviders',
      },
    })
  }

  // @trackApiMethodMetadata({ url: '/cloudProviders', type: 'POST', disable: true })
  // createCloudProvider = async (body) => {
  //   const url = `/cloudProviders`
  //   const { uuid } = await this.client.basicPost<CreateCloudProviderResponse>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createCloudProvider',
  //     },
  //   })
  //   const cloudProviders = await this.getCloudProviders()
  //   return cloudProviders.find((cp) => cp.uuid === uuid)
  // }

  // @trackApiMethodMetadata({
  //   url: '/cloudProviders/:cloudProviderId',
  //   type: 'GET',
  //   params: ['cloudProviderId'],
  // })
  // getCloudProviderDetails = async (cpId) => {
  //   const url = `/cloudProviders/${cpId}`
  //   return this.client.basicGet<CloudProviderDetailsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getCloudProviderDetails',
  //     },
  //   })
  // }

  // @trackApiMethodMetadata({
  //   url: '/cloudProviders/:cloudProviderId/region/:regionName',
  //   type: 'GET',
  //   params: ['cloudProviderId', 'regionName'],
  // })
  // getCloudProviderRegionDetails = async (cpId, regionId) => {
  //   const url = `/cloudProviders/${cpId}/region/${regionId}`
  //   return this.client.basicGet<AwsCloudProviderRegionDetails | AzureCloudProviderRegionDetails>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getCloudProviderRegionDetails',
  //     },
  //   })
  // }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId',
    type: 'PUT',
    params: ['cloudProviderId'],
  })
  updateCloudProvider = async (cpId, body) => {
    const url = `/cloudProviders/${cpId}`
    return this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCloudProvider',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId',
    type: 'DELETE',
    params: ['cloudProviderId'],
  })
  deleteCloudProvider = async (cpId) => {
    const url = `/cloudProviders/${cpId}`
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCloudProvider',
      },
    })
  };

  [DataKeys.CloudProviders] = {
    // create: this.createCloudProvider.bind(this),
    list: this.getCloudProviders.bind(this),
    //details: this.getCloudProviderDetails.bind(this),
    //regionDetails: this.getCloudProviderDetails.bind(this),
    update: this.updateCloudProvider.bind(this),
    delete: this.deleteCloudProvider.bind(this),
  }

  /* Cloud Providers Types */
  @trackApiMethodMetadata({ url: '/cloudProvider/types', type: 'GET' })
  getCloudProviderTypes = async () => {
    const url = `/cloudProvider/types`
    return this.client.basicGet({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviderTypes',
      },
    })
  }

  /* Node Pools */
  @trackApiMethodMetadata({ url: '/nodePools', type: 'GET' })
  getNodePools = async () => {
    const url = `/nodePools`
    return this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodePools',
      },
    })
  }

  /* Nodes */
  @trackApiMethodMetadata({ url: '/nodes', type: 'GET' })
  getNodes = async () => {
    const url = `/nodes`
    return this.client.basicGet<Node[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodes',
      },
    })
  };

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/api/v1/nodes',
  //   type: 'GET',
  //   params: ['clusterId'],
  // })
  // getK8sNodes = async (clusterId) => {
  //   const url = getApiUrl(`api/v1/nodes`, clusterId)
  //   const data = await this.client.basicGet<K8SNodeResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getK8sNodes',
  //     },
  //   })
  //   return data?.items?.map(this.convertResource(clusterId)) || []
  // };

  [DataKeys.Nodes] = {
    list: this.getNodes,
  }

  /* SSH Keys */
  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId/region/:regionName',
    type: 'PUT',
    params: ['cloudProviderId', 'regionName'],
  })
  importSshKey = async (cpId, regionId, body) => {
    const url = `/cloudProviders/${cpId}/region/${regionId}`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'importSshKey',
      },
    })
  }

  /* Clusters */
  @trackApiMethodMetadata({ url: '/clusters', type: 'GET' })
  getClusters = async () => {
    const url = `/clusters`
    return this.client.basicGet<ClusterElement[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusters',
      },
    })
  }

  getSunpikeApis = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com`
    return this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusters',
      },
    })
  }

  // getImportedClusters = async () => {
  //   const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/clusters`
  //   const response = await this.client.basicGet<GCluster<ImportedCluster>>({
  //     url,
  //     version: 'v4',
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusters',
  //     },
  //   })
  //   return normalizeImportedClusters(response)
  // }

  @trackApiMethodMetadata({ url: '/clusters/:clusterId', type: 'GET', params: ['clusterId'] })
  getClusterDetails = async (clusterId) => {
    const url = `/clusters/${clusterId}`
    return this.client.basicGet<ClusterElement>({
      url,
      // version: 'v4', // TODO update to v4 when backend releases 5.1
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDetails',
      },
    })
  }

  getK8sSupportedRoleVersions = async () => {
    const url = '/clusters/supportedRoleVersions'
    const supportedRoleVersions = await this.client.basicGet<SupportedRoleVersions>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDetails',
      },
    })
    return supportedRoleVersions
  }

  createCluster = async (body) => {
    // Note: This API response only returns new `uuid` in the response.
    // You might want to do a GET afterwards if you need any of the cluster information.
    const url = `/clusters`
    return this.client.basicPost<ClusterElement>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCluster',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/clusters/:clusterId', type: 'PUT', params: ['clusterId'] })
  updateCluster = async (clusterId, body) => {
    const url = `/clusters/${clusterId}`
    await this.client.basicPut<ClusterElement>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCluster',
      },
    })
    const clusters = await this.getClusters()
    return clusters?.find((cluster) => cluster.uuid === clusterId) || ({} as ClusterElement)
  }

  upgradeClusterNodes = async (clusterId, type, body = null) => {
    const url = createUrlWithQueryString(`/clusters/${clusterId}/upgrade?type=${type}`)
    return this.client.basicPost<ClusterElement>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'upgradeClusterNodes',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId',
    type: 'DELETE',
    params: ['clusterId'],
  })
  deleteCluster = async (clusterId) => {
    const url = `/clusters/${clusterId}`
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCluster',
      },
    })
  };

  [DataKeys.Clusters] = {
    list: this.getClusters,
  }

  // @param clusterId = cluster.uuid
  // @param nodes = [{ uuid: node.uuid, isMaster: (true|false) }]
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/attach',
    type: 'POST',
    params: ['clusterId'],
  })
  attachNodes = async (clusterId, body) => {
    const url = `/clusters/${clusterId}/attach`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'attachNodes',
      },
    })
  }

  // @param clusterId = cluster.uuid
  // @param nodes = [node1Uuid, node2Uuid, ...]
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/detach',
    type: 'POST',
    params: ['clusterId'],
  })
  detachNodes = async (clusterId, nodeUuids) => {
    const body = nodeUuids.map((nodeUuid) => ({ uuid: nodeUuid }))
    const url = `/clusters/${clusterId}/detach`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'detachNodes',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/webcli/:clusterId', type: 'POST', params: ['clusterId'] })
  getCliToken = async (clusterId, namespace) => {
    const url = `/webcli/${clusterId}`
    const response = await this.client.basicPost<any>({
      url,
      body: {
        namespace,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCliToken',
      },
    })
    return response.token
  }

  @trackApiMethodMetadata({
    url: '/kubeconfig/:clusterId',
    type: 'GET',
    params: ['clusterId'],
  })
  getKubeConfig = async (clusterId, forceCertificate = false) => {
    const url = forceCertificate
      ? `/kubeconfig/${clusterId}?force_cert_auth=true`
      : `/kubeconfig/${clusterId}`
    return this.client.basicGet<string>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubeConfig',
      },
    })
  }

  discoverExternalClusters = async (body) => {
    const url = '/externalClusters/discover'
    const discoveredClusters = await this.client.basicPost<any>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'discoverExternalClusters',
      },
    })
    return discoveredClusters
  }

  @trackApiMethodMetadata({
    url: '/externalClusters/register',
    type: 'POST',
  })
  registerExternalCluster = async (body) => {
    const url = '/externalClusters/register'
    const registeredCluster = await this.client.basicPost<any>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'registerExternalCluster',
      },
    })
    return registeredCluster
  }

  deregisterExternalCluster = async (id) => {
    const url = `/externalClusters/${id}/deregister`
    return this.client.basicPost({
      url,
      body: {},
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deregisterExternalCluster',
      },
    })
  }

  /* k8s API */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/version',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'K8s Version' },
  })
  getKubernetesVersion = async (clusterId) => {
    const url = getApiUrl(`version`, clusterId)
    return this.client.basicGet<GetKubernetesVersion>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubernetesVersion',
      },
    })
  }

  /* k8s API */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/addonversions',
    version: 'v4',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Addon Versions' },
  })
  getAddonVersions = async (clusterId) => {
    const url = `/clusters/${clusterId}/addonversions`
    const addonVersions = await this.client.basicGet<GetAddonVersions>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAddonVersions',
      },
    })
    // TODO: Update this with a GetOneAction which  will handle an object response
    // Here, converted the single object to an array as the current useListAction is only meant to work with array responses.
    return ensureArray(addonVersions)
  }

  convertResource = (clusterId) => <T extends IGenericPayloadWithMetadata>(
    item: T,
  ): IGenericResource<T> => ({
    ...item,
    clusterId,
    name: item.metadata.name,
    created: item.metadata.creationTimestamp,
    id: item.metadata.uid,
    ...(item.metadata.namespace ? { namespace: item.metadata.namespace } : {}),
  })

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/pods',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Pod' },
  })
  getClusterPods = async (clusterId: string, namespace?: string) => {
    const url = getApiUrl(
      namespace ? `api/v1/namespaces/${namespace}/pods` : `api/v1/pods`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterPods>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterPods',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'Pod' },
  // })
  // getClusterPod = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
  //   const data = await this.client.basicGet<Pod>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusterPod',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/deployments',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  getClusterDeployments = async (clusterId: string, namespace?: string) => {
    const url = getApiUrl(
      namespace ? `apis/apps/v1/namespaces/${namespace}/deployments` : `apis/apps/v1/deployments`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterDeployments>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDeployments',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  getClusterDeployment = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicGet<GetClusterDeploymentsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:deployment',
    type: 'DELETE',
    params: ['namespace', 'deployment'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  deleteDeployment = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicDelete<GetClusterDeploymentsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/services',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Service' },
  })
  getClusterKubeServices = async (clusterId, namespace) => {
    const url = getApiUrl(
      namespace ? `api/v1/namespaces/${namespace}/services` : `api/v1/services`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterKubeServices>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterKubeServices',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:service',
    type: 'GET',
    params: ['clusterId', 'namespace', 'service'],
  })
  getClusterKubeService = async (clusterId, namespace, service) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${service}`, clusterId)
    const data = await this.client.basicGet<GetClusterKubeServicesItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterKubeService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
  })
  updateClusterKubeService = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterKubeServicesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterKubeService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
  })
  patchClusterKubeService = async (
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  ) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${name}`, clusterId)
    const data = await this.client.basicPatch<GetClusterKubeServicesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchClusterKubeService',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/networking.k8s.io/v1/ingresses',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'Ingress' },
  // })
  // getIngresses = async (clusterId) => {
  //   const url = getApiUrl(`apis/networking.k8s.io/v1/ingresses`, clusterId)
  //   const data = await this.client.basicGet<IngressesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getIngresses',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachineinstances',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // getVirtualMachineInstances = async (clusterId) => {
  //   const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachineinstances`, clusterId)
  //   const data = await this.client.basicGet<GetVirtualMachineInstancesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachineInstances',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachines',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  // })
  // getVirtualMachines = async (clusterId) => {
  //   const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachines`, clusterId)
  //   const data = await this.client.basicGet<GetVirtualMachines>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachines',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  getVirtualMachineByName = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineByName',
      },
    })
    return data
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // getVirtualMachineInstanceByName = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<GetVirtualMachineDetails>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachineInstanceByName',
  //     },
  //   })
  //   return data
  // }

  // getVirtualMachineVolumeDetails = async (clusterId, namespace, volumeType, name) => {
  //   // cdi.kubevirt.io/v1beta1/namespaces/default/datavolumes/dv-rootfs
  //   const url = getApiUrl(
  //     `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/${convertVolumeTypeToApiParam(
  //       volumeType,
  //     )}/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachineVolumeDetails',
  //     },
  //   })
  //   return data
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // createVirtualMachineInstance = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<IVirtualMachineInstance>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createVirtualMachineInstance',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  // })
  // updateVirtualMachine = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<IVirtualMachine>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateVirtualMachine',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  // })
  // patchVirtualMachine = async ({
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // }) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPatch<IVirtualMachine>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchVirtualMachine',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // updateVirtualMachineInstance = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<IVirtualMachineInstance>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateVirtualMachineInstance',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // patchVirtualMachineInstance = async ({
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // }) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPatch<IVirtualMachineInstance>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchVirtualMachineInstance',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  deleteVirtualMachineInstance = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVirtualMachineInstance',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  deleteVirtualMachine = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVirtualMachine',
      },
    })
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/subresources.kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name/:operation',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name', 'operation'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // vmiPowerOperation = async (clusterId, namespace, name, operation) => {
  //   const url = getApiUrl(
  //     `apis/subresources.kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}/${operation}`,
  //     clusterId,
  //   )
  //   return this.client.basicPut<IVirtualMachineInstance>({
  //     url,
  //     body: {},
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'vmiPowerOperation',
  //     },
  //   })
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/subresources.kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name/:operation',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name', 'operation'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  // })
  // virtualMachinePowerOperation = async (clusterId, namespace, name, operation) => {
  //   const url = getApiUrl(
  //     `apis/subresources.kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}/${operation}`,
  //     clusterId,
  //   )
  //   return this.client.basicPut<IVirtualMachine>({
  //     url,
  //     body: {},
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'virtualMachinePowerOperation',
  //     },
  //   })
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachineinstancemigrations',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'LiveMigration' },
  // })
  // getLiveMigrations = async (clusterId) => {
  //   const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachineinstancemigrations`, clusterId)
  //   const data = await this.client.basicGet<LiveMigrationsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getLiveMigrations',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstancemigrations',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  // })
  // virtualMachineInstanceMigration = async (clusterId, namespace, body) => {
  //   const vmiName = body?.spec?.vmiName
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstancemigrations`,
  //     clusterId,
  //   )
  //   await this.client.basicPost<unknown>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'virtualMachineInstanceMigration',
  //     },
  //   })
  //   // Want to return the VMI object to update the cache
  //   const data = await this.client.basicGet<IVirtualMachineInstance>({
  //     url: getApiUrl(
  //       `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${vmiName}`,
  //       clusterId,
  //     ),
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachineInstanceByName',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  // })
  // createVirtualMachine = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(`apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines`, clusterId)
  //   const data = await this.client.basicPost<IVirtualMachine>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createVirtualMachine',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineinstancetypes',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'InstanceType' },
  // })
  // getInstanceTypes = async (clusterId) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineinstancetypes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<InstanceTypesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getInstanceTypes',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: true, k8sResource: 'InstanceType' },
  // })
  // createInstanceType = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<IInstanceType>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createInstanceType',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'InstanceType' },
  // })
  // updateInstanceType = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<IInstanceType>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateInstanceType',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'InstanceType' },
  })
  deleteInstanceType = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteInstanceType',
      },
    })
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  // })
  // getClusterInstanceTypes = async (clusterId) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<ClusterInstanceTypesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusterInstanceTypes',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes',
  //   type: 'POST',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  // })
  // createClusterInstanceType = async (clusterId, body) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<IClusterInstanceType>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createClusterInstanceType',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'name'],
  //   error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  // })
  // updateClusterInstanceType = async ({ clusterId, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<IClusterInstanceType>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateClusterInstanceType',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/:name',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  })
  deleteClusterInstanceType = async (clusterId, name) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterInstanceType',
      },
    })
  }

  // getVirtualMachinePresets = async (clusterId, namespace) => {
  //   const url = getApiUrl(
  //     `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstancepresets`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<VirtualMachineInstancePresetsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getVirtualMachinePresets',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/k8s.cni.cncf.io/v1/network-attachment-definitions',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkAttachmentDefinition',
  //   },
  // })
  // getNetworks = async (clusterId, namespace?) => {
  //   const url = getApiUrl(
  //     namespace
  //       ? `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions`
  //       : `apis/k8s.cni.cncf.io/v1/network-attachment-definitions`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<NetworkAttachmentDefinitionsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getNetworks',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/k8s.cni.cncf.io/v1/namespaces/:namespace/network-attachment-definitions/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NetworkAttachmentDefinition' },
  })
  getNetwork = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNetwork',
      },
    })
    return data
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/k8s.cni.cncf.io/v1/namespaces/:namespace/network-attachment-definitions',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkAttachmentDefinition',
  //   },
  // })
  // createNetwork = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<NetworksItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createNetwork',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/k8s.cni.cncf.io/v1/namespaces/:namespace/network-attachment-definitions/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkAttachmentDefinition',
  //   },
  // })
  // updateNetwork = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<NetworksItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateNetwork',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/k8s.cni.cncf.io//v1/namespaces/:namespace/network-attachment-definitions/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'NetworkAttachmentDefinition',
    },
  })
  patchNetwork = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(
      `apis/k8s.cni.cncf.io//v1/namespaces/${namespace}/network-attachment-definitions/${name}`,
      clusterId,
    )
    const data = await this.client.basicPatch<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchNetwork',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/api/k8s.cni.cncf.io/v1/namespaces/:namespace/network-attachment-definitions/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkAttachmentDefinition',
  //     // k8sResourceAccessor: 'params.name',
  //   },
  // })
  // deleteNetwork = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(
  //     `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicDelete<NetworksItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteNetwork',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // getIpAllocations = async (clusterId) => {
  //   const url = getApiUrl(`apis/dhcp.plumber.k8s.pf9.io/v1alpha1/ipallocations`, clusterId)
  //   const data = await this.client.basicGet<IPAllocationsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getIpAllocations',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/networkplugins',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkPlugin',
  //   },
  // })
  // getNetworkPlugins = async (clusterId) => {
  //   const url = getApiUrl(`apis/plumber.k8s.pf9.io/v1/networkplugins`, clusterId)
  //   const data = await this.client.basicGet<NetworkPluginsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getNetworkPlugins',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/networkplugins/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'NetworkPlugin' },
  })
  getNetworkPlugin = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/networkplugins/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNetworkPlugin',
      },
    })
    return data
    // I don't think we want the converted version when editing the yaml right?
    // return this.convertResource(clusterId)(data)
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/networkplugins',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkPlugin',
  //   },
  // })
  // createNetworkPlugin = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/networkplugins`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<NetworkPluginItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createNetworkPlugin',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/networkplugins/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkPlugin',
  //   },
  // })
  // updateNetworkPlugin = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/networkplugins/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<NetworkPluginItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateNetworkPlugin',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/networkplugins/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'NetworkPlugin',
    },
  })
  patchNetworkPlugin = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(
      `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/networkplugins/${name}`,
      clusterId,
    )
    const data = await this.client.basicPatch<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchNetworkPlugin',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/api/plumber.k8s.pf9.io/v1/namespaces/:namespace/networkplugins/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'NetworkPlugin',
  //     k8sResourceAccessor: 'params.name',
  //   },
  // })
  // deleteNetworkPlugin = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/networkplugins/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicDelete<NetworkPluginItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteNetworkPlugin',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/hostnetworktemplates',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'HostNetworkTemplate',
  //   },
  // })
  // getHostNetworkTemplates = async (clusterId) => {
  //   const url = getApiUrl(`apis/plumber.k8s.pf9.io/v1/hostnetworktemplates`, clusterId)
  //   const data = await this.client.basicGet<HostNetworkTemplatesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getHostNetworkTemplates',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/hostnetworktemplates/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'HostNetworkTemplate' },
  // })
  // getHostNetworkTemplate = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/hostnetworktemplates/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<HostNetworkTemplate>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getHostNetworkTemplate',
  //     },
  //   })
  //   return data
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/hostnetworktemplates',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'HostNetworkTemplate',
  //   },
  // })
  // createHostNetworkTemplate = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/hostnetworktemplates`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<HostNetworkTemplate>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createHostNetworkTemplate',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/hostnetworktemplates/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'HostNetworkTemplate' },
  // })
  // updateHostNetworkTemplate = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/hostnetworktemplates/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<HostNetworkTemplate>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateHostNetworkTemplate',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/hostnetworktemplates/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'HostNetworkTemplate' },
  })
  patchHostNetworkTemplate = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(
      `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/hostnetworktemplates/${name}`,
      clusterId,
    )
    const data = await this.client.basicPatch<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchHostNetworkTemplate',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/plumber.k8s.pf9.io/v1/namespaces/:namespace/hostnetworktemplates/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'HostNetworkTemplate' },
  })
  deleteHostNetworkTemplate = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/plumber.k8s.pf9.io/v1/namespaces/${namespace}/hostnetworktemplates/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteHostNetworkTemplate',
      },
    })
  }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/datavolumes',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'DataVolume' },
  // })
  // getDataVolumes = async (clusterId: string, namespace?: string) => {
  //   const url = getApiUrl(
  //     namespace
  //       ? `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes`
  //       : `apis/cdi.kubevirt.io/v1beta1/datavolumes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<DataVolumesResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getDataVolumes',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/namespaces/:namespace/datavolumes/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'DataVolume' },
  })
  getDataVolume = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getDataVolume',
      },
    })
    return data
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/namespaces/:namespace/datavolumes',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'DataVolume',
  //     k8sResourceAccessor: 'response.config.data.metadata.name',
  //   },
  // })
  // createDataVolume = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(
  //     `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPost<DataVolumesItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createDataVolume',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/namespaces/:namespace/datavolumes/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'DataVolume',
  //   },
  // })
  // updateDataVolume = async ({ clusterId, namespace, name, body }) => {
  //   const url = getApiUrl(
  //     `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<DataVolumesItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateDataVolume',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/namespaces/:namespace/datavolumes/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: {
  //     isClusterError: true,
  //     k8sResource: 'DataVolume',
  //   },
  // })
  // patchDataVolume = async ({
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // }) => {
  //   const url = getApiUrl(
  //     `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPatch<DataVolumesItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchDataVolume',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/apis/cdi.kubevirt.io/v1beta1/namespaces/:namespace/datavolumes/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'DataVolume', k8sResourceAccessor: 'params.name' },
  // })
  // deleteDataVolume = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(
  //     `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/datavolumes/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicDelete<DataVolumesItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteDataVolume',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createPod = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods`, clusterId)
    const data = await this.client.basicPost<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPod',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
    },
  })
  updatePod = async ({ clusterId, namespace, name, body, contentType }) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePod',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
    },
  })
  patchPod = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicPatch<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchPod',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:podName',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'podName'],
    error: { isClusterError: true, k8sResource: 'Pod', k8sResourceAccessor: 'params.name' },
  })
  deletePod = async (clusterId, namespace, name) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicDelete<GetClusterPodsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePod',
      },
    })
    return this.convertResource(clusterId)(data)
  };

  [DataKeys.Pods] = {
    create: this.createPod.bind(this),
    list: this.getClusterPods.bind(this),
    delete: this.deletePod.bind(this),
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Deployment',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createDeployment = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments`, clusterId)
    const data = await this.client.basicPost<GetClusterDeploymentsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Deployment',
    },
  })
  updateDeployment = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterDeploymentsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Deployment',
    },
  })
  patchDeployment = async (
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  ) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicPatch<GetClusterDeploymentsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchDeployment',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  };

  [DataKeys.Deployments] = {
    create: this.createDeployment.bind(this),
    list: this.getClusterDeployments.bind(this),
    delete: this.deleteDeployment.bind(this),
    update: this.updateDeployment.bind(this),
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Service',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createService = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services`, clusterId)
    const data = await this.client.basicPost<GetClusterKubeServicesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:service',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'service'],
    error: { isClusterError: true, k8sResource: 'Service' },
  })
  deleteService = async (clusterId, namespace, name) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${name}`, clusterId)
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteService',
      },
    })
  }

  // [DataKeys.KubeServices] = {
  //   create: this.createService.bind(this),
  //   list: this.getClusterKubeServices.bind(this),
  //   delete: this.deleteService.bind(this),
  // }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/serviceaccounts',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'ServiceAccount' },
  })
  createServiceAccount = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/serviceaccounts`, clusterId)
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createServiceAccount',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/serviceaccounts',
    type: 'GET',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'ServiceAccount' },
  })
  getServiceAccounts = async (clusterId, namespace) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/serviceaccounts`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getServiceAccounts',
      },
    })
    return response && response.items
  }

  /* Managed Apps */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/prometheuses',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  getPrometheusInstances = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/prometheuses`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusInstances',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  updatePrometheusInstance = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [
      { op: 'replace', path: '/spec/replicas', value: data.replicas },
      { op: 'replace', path: '/spec/retention', value: data.retention },
      { op: 'replace', path: '/spec/resources/requests/cpu', value: data.cpu },
      { op: 'replace', path: '/spec/resources/requests/memory', value: data.memory },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheuses/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusInstance',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  deletePrometheusInstance = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheuses/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusInstance',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  createPrometheusInstance = async (clusterId, data) => {
    const requests: { cpu?: string; memory?: string } = {}
    if (data.cpu) {
      requests.cpu = data.cpu
    }
    if (data.memory) {
      requests.memory = data.memory
    }
    // if (data.storage) { requests.storage = data.storage }

    const apiVersion = 'monitoring.coreos.com/v1'

    const serviceMonitor = {
      prometheus: data.name,
      role: 'service-monitor',
    }

    const appLabels = keyValueArrToObj(data.appLabels)
    const ruleSelector = {
      prometheus: data.name,
      role: 'alert-rules',
    }

    const prometheusBody = {
      apiVersion,
      kind: 'Prometheus',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      spec: {
        replicas: data.replicas,
        retention: data.retention,
        resources: { requests },
        serviceMonitorSelector: { matchLabels: serviceMonitor },
        serviceAccountName: data.serviceAccountName,
        ruleSelector: { matchLabels: ruleSelector },
      },
    }

    // TODO: How do we specifiy "Enable persistent storage" in the API call?  What does this field mean in the
    // context of a Prometheus Instance?  Where will it be stored?  Do we need to specify PVC and StorageClasses?

    const serviceMonitorBody = {
      apiVersion,
      kind: 'ServiceMonitor',
      metadata: {
        name: `${data.name}-service-monitor`,
        namespace: data.namespace,
        labels: serviceMonitor,
      },
      spec: {
        endpoints: [{ port: data.port }],
        selector: { matchLabels: appLabels },
      },
    }

    /*
    let alertManagerBody = {
      // TODO: what goes in here
    }
    */

    const prometheusRulesBody = {
      apiVersion,
      kind: 'PrometheusRule',
      metadata: {
        labels: ruleSelector,
        name: `${data.name}-prometheus-rules`,
        namespace: data.namespace,
      },
      spec: {
        groups: [
          {
            name: `${data.name}-rule-group`,
            rules: data.rules,
          },
        ],
      },
    }

    const prometheusUrl = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/prometheuses`,
      clusterId,
    )
    const response = await this.client.basicPost({
      url: prometheusUrl,
      body: prometheusBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    const serviceMonitorUrl = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/servicemonitors`,
      clusterId,
    )
    await this.client.basicPost({
      url: serviceMonitorUrl,
      body: serviceMonitorBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/prometheusrules`,
      clusterId,
    )
    await this.client.basicPost({
      url,
      body: prometheusRulesBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    // this.client.basicPost(getApiUrl(/monitoring.coreos.com/v1/alertmanagers`, alertManagerBody), clusterId)
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/alerts',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'AlertManagerAlert' },
  })
  getAlertManagerAlerts = async (clusterId): Promise<AlertManagerAlert[]> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/alerts`,
      clusterId,
    )
    const alerts = await this.client.basicGet<AlertManagerRaw[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlerts',
      },
    })
    return alerts?.map((alert) => ({
      ...alert,
      clusterId,
      id: `${alert.fingerprint}-${clusterId}`,
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  getAlertManagerSilences = async (clusterId): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences`,
      clusterId,
    )
    const silences = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSilences',
      },
    })
    return silences?.map((silence) => ({
      ...silence,
      clusterId: clusterId,
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  createAlertManagerSilence = async (clusterId, body): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences`,
      clusterId,
    )
    const silence = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSilence',
      },
    })
    return {
      ...silence,
      clusterId: clusterId,
    }
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silence/:silenceId',
    type: 'DELETE',
    params: ['clusterId', 'silenceId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  deleteAlertManagerSilence = async (clusterId, silenceId): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silence/${silenceId}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSilence',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/alerts',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusAlert' },
  })
  getPrometheusAlerts = async (clusterId) => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/alerts`,
      clusterId,
    )
    const response = await this.client.basicGet<GetPrometheusAlerts>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlerts',
      },
    })
    return response.alerts.map((alert) => ({
      ...alert,
      clusterId: clusterId,
      id: uuid.v4(),
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/rules',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  getPrometheusAlertRules = async (clusterId) => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/rules`,
      clusterId,
    )
    const response = await this.client.basicGet<GetPrometheusAlertRules>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlertRules',
      },
    })
    const alertRules = response.groups
      .flatMap((group) => {
        return group.rules
      })
      .filter((rule) => rule.type === 'alerting')
      .map((rule) => ({
        ...rule,
        clusterId,
        id: uuid.v4(),
      }))
    return alertRules
  }

  // @trackApiMethodMetadata({
  //   url:
  //     '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/query_range',
  //   type: 'GET',
  //   params: ['clusterId', 'query', 'start', 'end', 'step'],
  //   error: { isClusterError: true, k8sResource: 'PrometheusAlert' },
  // })
  // getPrometheusAlertsOverTime = async (
  //   clusterId,
  //   startTime,
  //   endTime,
  //   step,
  // ): Promise<IAlertOverTime[]> => {
  //   const url = getApiUrl(
  //     `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/query_range?query=ALERTS&start=${startTime}&end=${endTime}&step=${step}`,
  //     clusterId,
  //   )
  //   const response = await this.client.basicGet<GetPrometheusAlertsOverTime>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getPrometheusAlertsOverTime',
  //     },
  //   })
  //   return response.result.map((alert) => ({
  //     ...alert,
  //     startTime,
  //     endTime,
  //     clusterId,
  //   }))
  // }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/servicemonitors',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  getPrometheusServiceMonitors = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/servicemonitors`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusServiceMonitors',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/servicemonitors/:serviceMonitor',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'serviceMonitor'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  updatePrometheusServiceMonitor = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [
      {
        op: 'replace',
        path: '/metadata/labels',
        value: data.labels,
      },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/servicemonitors/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusServiceMonitor',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/servicemonitors/:serviceMonitor',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'serviceMonitor'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  deletePrometheusServiceMonitor = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/servicemonitors/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusServiceMonitor',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/prometheusrules',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  getPrometheusRules = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/prometheusrules`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusRules',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheusrules/:prometheusRule',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'prometheusRule'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  updatePrometheusRules = async (rulesObject) => {
    const { clusterUuid, namespace, name } = rulesObject
    const body = [
      {
        op: 'replace',
        path: '/spec/groups/0/rules',
        value: rulesObject.rules,
      },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheusrules/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusRules',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheusrules/:prometheusRule',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusRule'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  deletePrometheusRule = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheusrules/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusRule',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/alertmanagers',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  getPrometheusAlertManagers = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/alertmanagers`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlertManagers',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/alertmanagers/:alertManager',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'alertManager'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  updatePrometheusAlertManager = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [{ op: 'replace', path: '/spec/replicas', value: data.replicas }]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/alertmanagers/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusAlertManager',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/alertmanagers/:alertManager',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'alertManager'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  deletePrometheusAlertManager = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/alertmanagers/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusAlertManager',
      },
    })
  }

  // getPrometheusDashboardLink = async (instance) =>
  //   `${await this.getApiEndpoint()}/clusters/${instance.clusterUuid}/k8sapi${instance.dashboard}`

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/api/v1',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: true, k8sResource: 'APIResourceList' },
  // })
  // getCoreApiResourcesList = async (clusterId) => {
  //   const url = getApiUrl(`api/v1`, clusterId)
  //   return this.client.basicGet<IRbacAPIGroup>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getCoreApiResourcesList',
  //     },
  //   })
  // }

  // Get addons for ALL clusters
  // getAllClusterAddons = async (labelSelector = undefined): Promise<ClusterAddon[]> => {
  //   const url = '/sunpike/apis/sunpike.platform9.com/v1alpha2/clusteraddons'
  //   const result = await this.client.basicGet<ClusterAddons>({
  //     url: labelSelector ? createUrlWithQueryString(url, { labelSelector }) : url,
  //     version: 'v4',
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusterAddons',
  //     },
  //   })
  //   return result?.items
  // }

  // getClusterAddons = async (clusterId, namespace = 'default') => {
  //   const clusterLabel = `sunpike.pf9.io/cluster=${clusterId}`
  //   const url = createUrlWithQueryString(
  //     `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons`,
  //     { labelSelector: clusterLabel },
  //   )
  //   const result = await this.client.basicGet<ClusterAddons>({
  //     url,
  //     version: 'v4',
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusterAddons',
  //     },
  //   })
  //   return result?.items
  // }

  // TODO: Should these be moved to sunpike? Or do they have a different
  // base URL?
  deleteClusterAddon = async (addonName) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusteraddons/${addonName}`
    return this.client.basicDelete({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterAddon',
      },
    })
  }

  addClusterAddon = async (body) => {
    const namespace = body?.metadata?.namespace || 'default'
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons`
    return this.client.basicPost({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addClusterAddon',
      },
    })
  }

  editClusterAddon = async (addonName, body) => {
    const namespace = body?.metadata?.namespace || 'default'
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons/${addonName}`
    return this.client.basicPatch({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'editClusterAddon',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
  }

  // getClusterAgents = async () => {
  //   const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${this.client.activeProjectId}/clusteragents`
  //   const result = await this.client.basicGet<ClusterAgentsResponse>({
  //     url,
  //     version: 'v4',
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getClusterAgents',
  //     },
  //   })
  //   return result?.items
  // }

  @trackApiMethodMetadata({
    url: '/externalClusters/:clusterId/deploymentYAML',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false },
  })
  getEcoInstallationYaml = async (clusterId) => {
    const url = `/externalClusters/${clusterId}/deploymentYAML`
    const result = await this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEcoInstallationYaml',
      },
    })
    return result
  }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/metrics.k8s.io/v1beta1/namespaces/:namespace/pods',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: true, k8sResource: 'PodMetricsList' },
  // })
  // getPodsMetrics = async (clusterId, namespace) => {
  //   const url = getApiUrl(`apis/metrics.k8s.io/v1beta1/namespaces/${namespace}/pods`, clusterId)
  //   const data = await this.client.basicGet<PodMetricsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getPodsMetrics',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name/log?=container:container',
    type: 'GET',
    params: ['clusterId', 'namespace', 'pod', 'container'],
    error: { isClusterError: true, k8sResource: 'Pod Logs' },
  })
  getPodLogs = async (clusterId, namespace, pod, params) => {
    const url = getApiUrl(`/api/v1/namespaces/${namespace}/pods/${pod}/log`, clusterId, {}, params)

    const data = await this.client.rawGet<string>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPodLogs',
      },
      config: this.client.getAuthHeaders(),
    })
    return data
  }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/statefulsets',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSets' },
  // })
  // getStatefulSets = async (clusterId: string, namespace?: string) => {
  //   const url = namespace
  //     ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets`, clusterId)
  //     : getApiUrl(`apis/apps/v1/statefulsets`, clusterId)
  //   const data = await this.client.basicGet<StatefulSetResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getStatefulSets',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSets' },
  // })
  // getStatefulSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
  //   const data = await this.client.basicGet<StatefulSet>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getStatefulSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSets' },
  // })
  // createStatefulSet = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets`, clusterId)
  //   const data = await this.client.basicPost<StatefulSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createStatefulSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSet' },
  // })
  // updateStatefulSet = async (clusterId, namespace, name, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
  //   const data = await this.client.basicPut<StatefulSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateStatefulSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSet' },
  // })
  // patchStatefulSet = async (
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // ) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
  //   const data = await this.client.basicPatch<StatefulSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchStatefulSet',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'StatefulSet' },
  // })
  // deleteStatefulSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
  //   const data = await this.client.basicDelete<StatefulSet>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteStatefulSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/replicasets',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSets' },
  // })
  // getReplicaSets = async (clusterId: string, namespace?: string) => {
  //   const url = namespace
  //     ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets`, clusterId)
  //     : getApiUrl(`apis/apps/v1/replicasets`, clusterId)
  //   const data = await this.client.basicGet<ReplicaSetsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getReplicaSets',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  // })
  // getReplicaSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
  //   const data = await this.client.basicGet<ReplicaSetsItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getReplicaSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  // })
  // createReplicaSet = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets`, clusterId)
  //   const data = await this.client.basicPost<ReplicaSetsItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createReplicaSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  // })
  // updateReplicaSet = async (clusterId, namespace, name, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
  //   const data = await this.client.basicPut<ReplicaSetsItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateReplicaSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  // })
  // patchReplicaSet = async (
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // ) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
  //   const data = await this.client.basicPatch<ReplicaSetsItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchReplicaSet',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  // })
  // deleteReplicaSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
  //   const data = await this.client.basicDelete<ReplicaSetsItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteReplicaSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/cronjobs',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'Cronjobs' },
  // })
  // getCronjobs = async (clusterId, apiVersion = 'v1', namespace?: string) => {
  //   const url = namespace
  //     ? getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs`, clusterId)
  //     : getApiUrl(`apis/batch/${apiVersion}/cronjobs`, clusterId)
  //   const data = await this.client.basicGet<CronjobResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getCronjobs',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Cronjobs' },
  // })
  // getCronjob = async (clusterId, namespace, name, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<CronjobItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getCronjob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: false, k8sResource: 'Cronjob' },
  // })
  // createCronjob = async (clusterId, namespace, body, apiVersion = 'v1') => {
  //   const url = getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs`, clusterId)
  //   const data = await this.client.basicPost<CronjobItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createCronjob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Cronjob' },
  // })
  // updateCronjob = async (clusterId, namespace, name, body, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<CronjobItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateCronjob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Cronjob' },
  // })
  // deleteCronjob = async (clusterId, namespace, name, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicDelete<CronjobItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteCronjob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/jobs',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'Jobs' },
  // })
  // getJobs = async (clusterId, apiVersion = 'v1', namespace?: string) => {
  //   const url = namespace
  //     ? getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/jobs`, clusterId)
  //     : getApiUrl(`apis/batch/${apiVersion}/jobs`, clusterId)
  //   const data = await this.client.basicGet<JobsResponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getJobs',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Jobs' },
  // })
  // getJob = async (clusterId, namespace, name, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicGet<JobItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getJob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: false, k8sResource: 'Job' },
  // })
  // createJob = async (clusterId, namespace, body, apiVersion = 'v1') => {
  //   const url = getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/jobs`, clusterId)
  //   const data = await this.client.basicPost<JobItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createJob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Job' },
  // })
  // updateJob = async (clusterId, namespace, name, body, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPut<JobItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateJob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'Job' },
  // })
  // patchJob = async (
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   apiVersion = 'v1',
  //   contentType = 'application/merge-patch+json',
  // ) => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicPatch<JobItem>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchJob',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: true, k8sResource: 'Job' },
  // })
  // deleteJob = async (clusterId, namespace, name, apiVersion = 'v1') => {
  //   const url = getApiUrl(
  //     `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
  //     clusterId,
  //   )
  //   const data = await this.client.basicDelete<JobItem>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteJob',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/daemonsets',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSets' },
  // })
  // getDaemonSets = async (clusterId: string, namespace?: string) => {
  //   const url = namespace
  //     ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets`, clusterId)
  //     : getApiUrl(`apis/apps/v1/daemonsets`, clusterId)
  //   const data = await this.client.basicGet<GetDaemonSetsReponse>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getDaemonSets',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
  //   type: 'GET',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSet' },
  // })
  // getDaemonSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
  //   const data = await this.client.basicGet<DaemonSet>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getDaemonSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets',
  //   type: 'POST',
  //   params: ['clusterId', 'namespace'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSet' },
  // })
  // createDaemonSet = async (clusterId, namespace, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets`, clusterId)
  //   const data = await this.client.basicPost<DaemonSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'createDaemonSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/daemonsets/:name',
  //   type: 'PUT',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSet' },
  // })
  // updateDaemonSet = async (clusterId, namespace, name, body) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
  //   const data = await this.client.basicPut<DaemonSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'updateDaemonSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/daemonsets/:name',
  //   type: 'PATCH',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSet' },
  // })
  // patchDaemonSet = async (
  //   clusterId,
  //   namespace,
  //   name,
  //   body,
  //   contentType = 'application/merge-patch+json',
  // ) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
  //   const data = await this.client.basicPatch<DaemonSet>({
  //     url,
  //     body,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'patchDaemonSet',
  //       config: {
  //         headers: {
  //           'Content-Type': contentType,
  //         },
  //       },
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }
  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
  //   type: 'DELETE',
  //   params: ['clusterId', 'namespace', 'name'],
  //   error: { isClusterError: false, k8sResource: 'DaemonSet' },
  // })
  // deleteDaemonSet = async (clusterId, namespace, name) => {
  //   const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
  //   const data = await this.client.basicDelete<DaemonSet>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'deleteDaemonSet',
  //     },
  //   })
  //   return this.convertResource(clusterId)(data)
  // }

  // @trackApiMethodMetadata({
  //   url: '/clusters/:clusterId/k8sapi/apis/metal3.io/v1alpha1/baremetalhosts',
  //   type: 'GET',
  //   params: ['clusterId'],
  //   error: { isClusterError: false, k8sResource: 'BareMetalHosts' },
  // })
  // getBareMetalHosts = async (clusterId) => {
  //   const url = getApiUrl(`apis/metal3.io/v1alpha1/baremetalhosts`, clusterId)
  //   const data = await this.client.basicGet<any>({
  //     url,
  //     options: {
  //       clsName: this.getClassName(),
  //       mthdName: 'getBareMetalHosts',
  //     },
  //   })
  //   return data.items.map(this.convertResource(clusterId))
  // }
}

export default Qbert
