import { dissocPath } from 'ramda'
import { hasPathStr } from 'utils/fp'

export const cleanResource = (unwantedPaths = ['metadata.managedFields']) => (resource) => {
  return unwantedPaths.reduce((accum, pathStr) => {
    if (hasPathStr(pathStr, accum)) {
      return dissocPath(pathStr.split('.'), accum)
    }
    return accum
  }, resource)
}
