// App
export const moizeMaxSize = 1000
export const defaultUniqueIdentifier = 'id'
export const appUrlRoot = '/ui'
export const imageUrlRoot = `${appUrlRoot}/images`
export const loginUrl = `${appUrlRoot}/login`
export const setCookieUrl = `${appUrlRoot}/setcookie`
export const loginWithCookieUrl = `${appUrlRoot}/pmkft/login`
export const loginWithSsoUrl = `${appUrlRoot}/ssologin`
export const loginWithParams = `${appUrlRoot}/redirectLogin`
export const resetPasswordThroughEmailUrl = '/reset/password'
export const resetPasswordUrl = `${appUrlRoot}${resetPasswordThroughEmailUrl}`
export const forgotPasswordUrl = `${appUrlRoot}/forgot_password`
export const activateUserUrl = `${appUrlRoot}/users/activate`
export const logoutUrl = `${appUrlRoot}/logout`
// Todo: update dashboard url if the priority page changes
export const dashboardUrl = `${appUrlRoot}/openstack/dashboard`
export const ssoRedirectUrl = `/sso/IDP1/Shibboleth.sso/Login`
export const allKey = '__all__'
export const noneKey = '__none__'
export type TablePrefsParams = {
  visibleColumns?: string[]
  columnsOrder?: string[]
  rowsPerPage?: number
  orderBy?: string
  orderDirection?: 'asc' | 'desc'
}
export const listTablePrefs: Array<keyof TablePrefsParams> = [
  'visibleColumns',
  'columnsOrder',
  'rowsPerPage',
  'orderBy',
  'orderDirection',
]
export const uuidRegex = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  'i',
)
export const k8sPrefix = `${appUrlRoot}/kubernetes`
export const userAccountPrefix = `${appUrlRoot}/my-account`
export const pluginRoutePrefix = `${appUrlRoot}/:plugin`
export const helpUrl = `${k8sPrefix}/help/support`
export const helpUrlDefinition = `${k8sPrefix}/help/:tab`

export enum AppPlugins {
  MyAccount = 'my-account',
  Infrastructure = 'infrastructure',
  Kubernetes = 'kubernetes',
  OpenStack = 'openstack',
  KubeVirt = 'kubevirt',
  Metal3 = 'metal3',
  ArgoCD = 'argo-cd',
}
export const appPlugins = [
  AppPlugins.MyAccount,
  AppPlugins.Infrastructure,
  AppPlugins.Kubernetes,
  AppPlugins.OpenStack,
  AppPlugins.KubeVirt,
  AppPlugins.Metal3,
  AppPlugins.ArgoCD,
]

export enum CustomerTiers {
  Enterprise = 'enterprise',
  Freedom = 'freedom',
  Growth = 'growth',
  OEM = 'oem',
}

export const CustomerTierLabels = {
  [CustomerTiers.Enterprise]: 'Enterprise',
  [CustomerTiers.Freedom]: 'Freedom',
  [CustomerTiers.Growth]: 'Growth',
  [CustomerTiers.OEM]: 'OEM',
}

export const ssoEnabledTiers = [CustomerTiers.Enterprise, CustomerTiers.OEM]
export const themeEnabledTiers = [CustomerTiers.Enterprise, CustomerTiers.OEM]

export enum ClusterCloudPlatforms {
  EKS = 'eks',
  AKS = 'aks',
  GKE = 'gke',
}

export const defaultGroupMappingId = (domainName: string) => `${domainName.toLowerCase()}_mapping`

export const upgradeLinks = {
  [CustomerTiers.Freedom]: 'https://platform9.com/signup/growth/',
  [CustomerTiers.Growth]: 'https://platform9.com/pricing/request/',
}

export const planComparisonLink = 'https://platform9.com/pricing/comparison/'

export const onboardingAccessSetup = 'onboarding/api-access'
export const onboardingMonitoringSetup = 'onboarding/monitoring-enabled'
export const onboardingPodSetup = 'onboarding/pod-setup'

export const defaultEtcBackupPath = '/etc/pf9/etcd-backup'

// Errors
export const addError = 'ERR_ADD'
export const updateError = 'ERR_UPDATE'
export const deleteError = 'ERR_DELETE'
export const notFoundErr = 'ERR_NOT_FOUND'

// Documentation
export const pf9DocOrigin = 'https://platform9.com/docs'

// Ideas
export const pf9IdeasLink = 'https://ideas.platform9.com'

export enum LoadingGifs {
  BlueTiles = 'BlueTiles',
  BluePinkTiles = 'BluePinkTiles',
  Ellipsis = 'Ellipsis',
}

export const imageUrls = Object.freeze({
  logoPrimary: `${imageUrlRoot}/logo-color.png`,
  lightLogoPrimary: `${imageUrlRoot}/primary-logo-dark.svg`,
  lightManagementPane: `${imageUrlRoot}/management-plane-on-white.png`,
  darkLogoPrimary: `${imageUrlRoot}/primary-logo.svg`,
  darkManagementPane: `${imageUrlRoot}/management-plane.png`,
})

export const codeMirrorOptions = Object.freeze({
  mode: 'yaml',
})

/**
 * Default axios config
 * @type {object}
 */
export const defaultAxiosConfig = Object.freeze({
  // While headers here will apply for all requests, is important to remember that
  // some services like Openstack will require custom headers for specific methods
  // like "Content-Type": "application/json-patch+json" for PATCH
  headers: {
    common: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
  timeout: 120000,
})

// Misc
export const publicSlackLink = 'https://kplane.slack.com'
export const supportEmail = 'support-ft@platform9.com'
export const pmkftSignupLink = 'https://platform9.com/signup-flow/?sandbox=kubernetes'

export enum UserPreferences {
  FeatureFlags = 'featureFlags',
  Dashboard = 'dashboard',
  Cluster = 'cluster',
  Namespace = 'namespace',
  CloudProviders = 'cloudProviders',
  DefaultClusters = 'defaultClusters',
}

export enum GlobalPreferences {
  Theme = 'theme',
  CustomerExternalId = 'customer_external_uuid',
  FeatureFlags = 'featureFlags',
}

export interface GlobalFeatureFlags {
  isOnboarded?: boolean
}

// Cookies needed for SSO
export const SSO_REDIRECT_COOKIE = 'sso-redirect-cookie'
export const DOMAIN_NAME = 'domain'

export enum IPVersions {
  IPv4 = 4,
  IPv6 = 6,
}

export enum IconStatus {
  InProgress = 'inProgress',
  Success = 'success',
  Failed = 'failed',
  NotStarted = 'notStarted',
  Warning = 'warning',
  Deleting = 'deleting',
}

export const ICON_STATUS_MAP = {
  [IconStatus.InProgress]: 'In progress',
  [IconStatus.Success]: 'Created',
  [IconStatus.Failed]: 'Failed',
  [IconStatus.Warning]: 'Suspended',
  [IconStatus.Deleting]: 'Deleting',
}

export const ICON_STATUS_COLOR_MAP = {
  [IconStatus.InProgress]: 'primary',
  [IconStatus.Success]: 'success',
  [IconStatus.Failed]: 'error',
  [IconStatus.Warning]: 'warning',
  [IconStatus.Deleting]: 'secondary',
}

export const CLUSTER_STATUS_COLOR_MAP = {
  Pending: 'warning',
  Provisioning: 'success',
  Provisioned: 'success',
  Running: 'success',
  Deprovisioning: 'unknown',
  Failed: 'error',
  Deleting: 'secondary',
  Unknown: 'unknown',
}

export enum KaapiFeatures {
  EnableKaapi = 'enable_kaapi',
  EnableOpenStackStorage = 'enable_openstack_storage',
  EnableOpenStackNetworks = 'enable_openstack_networks',
  EnableControlPlaneClass = 'enable_control_plane_class',
}

export const CLUSTER_HOST_SCHEDULING = {
  enabled: 'Enabled',
  disabled: 'Disabled',
}
